import {SAVE_CLIENT, SAVE_CLIENTS_APPOINTMENT, DELETE_CLIENT, FETCH_CLIENTS_FULLFILLED, FETCH_CLIENTS, FETCH_SELECTED_CLIENT, FETCH_SELECTED_CLIENT_FULLFILLED, CLEAR_SELECTED_CLIENT, SAVE_CLIENT_FULLFILLED, ADD_CLIENT, DELETE_CLIENT_FULLFILLED} from 'actions/ClientActions'

const ClientReducer = (state = {
    clients: [],
    clients_pagination_info: {
        page: 1,
        page_size: 10,
        total_item_count: 0,
        total_page_counter: 0
    }
}, action) => {
    let {clients} = state;
    let {payload, type} = action;
    switch (type) {
        case ADD_CLIENT:
            return {...state,
                selectedClient:payload,
                loading: false
            }
        case SAVE_CLIENTS_APPOINTMENT:
            return  {...state,
                 loading: true}
        case SAVE_CLIENT:
        return  {...state,
             loading: true}
        case SAVE_CLIENT_FULLFILLED:
            return  {...state, 
                clients: filterOutClient(clients, payload.client).concat(payload.client),
                selectedClient:null,
                 loading: false}
        case FETCH_CLIENTS:
            return  {...state,
                 loading: true }
        case FETCH_CLIENTS_FULLFILLED:
            return  {...state,
                clients: payload.clients,
                clients_pagination_info: payload.clients_pagination_info,
                 loading: false }
        case FETCH_SELECTED_CLIENT:
            return  {...state,
                loading: true}
        case FETCH_SELECTED_CLIENT_FULLFILLED:
            return  {...state,
                selectedClient: payload,
                loading: false}
        case CLEAR_SELECTED_CLIENT:
            return  {...state,
                selectedClient: null
            }
        case DELETE_CLIENT:
        return {...state,
            loading: true}
        case DELETE_CLIENT_FULLFILLED:
            return {...state, 
                selectedClient:null,
                clients: filterOutClient(clients, {uuid:payload}),
                loading: false}
        default:
            return state
    }
}

const filterOutClient = (clients, client) => 
clients.filter(currentClient => currentClient.uuid !== client.uuid);

export default ClientReducer