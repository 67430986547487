import React, {Component} from 'react'
import { Button, Icon } from 'semantic-ui-react'



export function copyToClipboard(value) {
    /* Get the text field */
    //var copyText = document.getElementById("myInput");
  
    /* Select the text field */
    //copyText.select();
    //copyText.setSelectionRange(0, 99999); /*For mobile devices*/
  
    /* Copy the text inside the text field */
    //document.execCommand("copy");
    navigator.clipboard.writeText(value)
    /* Alert the copied text */
    //alert("Copied the text: " + copyText.value);
  }

export function CopyToClipboardButton(props) {
    return <Icon name='copy' style={{cursor:'pointer'}}onClick={() => copyToClipboard(props.text)} />
}