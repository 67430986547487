import React from 'react';
import { Header, Label, Divider, Form, Tab, Input, Button, Dropdown } from 'semantic-ui-react'
import { TextFormField } from 'widgets/FormWidgets';
import US_STATE_LIST from 'utils/usStateList';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import validateEmail from 'utils/emailValidation';

export const accountTab = (company, office, onChange, onSave) => {
  return (
    <Tab.Pane>
      <Form>
        <Header as='h3'>
          Account Profile
                </Header>
        <Form.Group widths='equal'>
          <TextFormField
            className="account-office-name"
            placeholder='Office Name'
            label='Office Name'
            valueKey='name'
            object={office}
            update={(value, key) => onChange('office', value, key)}
            control={Input}
          />
          <Form.Field>
            <label>Account ID</label>
            <Label fluid size="big">{company.cpas_site_id}</Label>
          </Form.Field>
        </Form.Group>
        <Form.Group widths='equal'>
          <TextFormField
            className="account-email"
            error={office.email && !validateEmail(office.email)}
            placeholder='Email'
            label='Email'
            valueKey='email'
            object={office}
            update={(value, key) => onChange('office', value, key)}
            control={Input}
          />
          <TextFormField
            className="account-website"
            placeholder='Website'
            label='Website'
            valueKey='website'
            object={office}
            update={(value, key) => onChange('office', value, key)}
            control={Input}
          />
        </Form.Group>
        <Form.Group>
          <TextFormField
            valueKey='area_code'
            update={(value, key) => onChange('office', value, key)}
            control={Input}
            label="Area Code"
            placeholder="xxx"
            object={office}
            width={2}
          />
          <TextFormField
            valueKey='phone_number'
            update={(value, key) => onChange('office', value, key)}
            control={Input}
            label="Phone Number"
            placeholder="xxxxxxx"
            object={office}
            width={8}
          />
          <TextFormField
            valueKey='extension'
            update={(value, key) => onChange('office', value, key)}
            control={Input}
            label="Ext."
            placeholder="#xxx"
            object={office}
            width={2}
          />
          <Form.Field>
            <label>Timezone</label>
            <Dropdown
              options={[{ value: "America/New_York", text: "Eastern - New York" },
              { value: "America/Denver", text: "Mountain - Denver" },
              { value: "America/Chicago", text: "Central - Chicago" },
              { value: "America/Phoenix", text: "Arizona - Phoenix" },
              { value: "America/Los_Angeles", text: "Pacific - Los Angeles" },
              { value: "America/Anchorage", text: "Alaska - Anchorage" },
              { value: "Pacific/Honolulu", text: "Hawaii - Honolulu" },
              { value: "America/Adak", text: "Alaska - Adak" },
              { value: "America/Boise", text: "Mountain - Boise" },
              { value: "America/Detroit", text: "Eastern - Detroit" },
              { value: "America/Indiana/Indianapolis", text: "Eastern - Indianapolis" },
              { value: "America/Indiana/Knox", text: "Mountain - Indiana/Knox" },
              { value: "America/Indiana/Marengo", text: "Eastern - Indiana/Marengo" },
              { value: "America/Indiana/Petersburg", text: "Eastern - Indiana/Petersburg" },
              { value: "America/Indiana/Tell_City", text: "Mountain - Indiana/Petersburg" },
              { value: "America/Indiana/Vevay", text: "Eastern - Indiana/Vevay" },
              { value: "America/Indiana/Vincennes", text: "Eastern - Indiana/Vincennes" },
              { value: "America/Indiana/Winamac", text: "Eastern - Indiana/Winamac" },
              { value: "America/Juneau", text: "Alaska - Juneau" },
              { value: "America/Kentucky/Louisville", text: "Eastern - Kentucky/Louisville" },
              { value: "America/Kentucky/Monticello", text: "Eastern - Kentucky/Monticello" },
              { value: "America/Menominee", text: "Central - Menominee" },
              { value: "America/Nome", text: "Alaska - Nome" },
              { value: "America/North_Dakota/Center", text: "Central - North Dakota/Center" },
              { value: "America/North_Dakota/New_Salem", text: "Central - North Dakota/New Salem" },
              { value: "America/Yakutat", text: "Alaska - Yakutat" },
              { value: "Pacific/Midway", text: "Pacific - Midway" },
              { value: "Pacific/Wake", text: "Pacific - Wake" },
              { value: "America/St_Thomas", text: "Virgin Islands - St Thomas" }]}
              scrolling
              search
              value={office.timezone_string}
              onChange={(event, { value }) => onChange('office', value, 'timezone_string')}
              selection
            />
          </Form.Field>
        </Form.Group>
        <Divider horizontal as="h3">Address</Divider>
        <Form.Group widths='equal'>
          <TextFormField
            placeholder='Street Address, P.O Box'
            label='Address Line1'
            valueKey='street_address'
            object={office}
            update={(value, key) => onChange('office', value, key)}
            control={Input}
          />
          <TextFormField
            placeholder='Unit, suite, building, floor etc'
            label='Address Line2'
            valueKey='extended_address'
            object={office}
            update={(value, key) => onChange('office', value, key)}
            control={Input}
          />
        </Form.Group>
        <Form.Group>
          <TextFormField
            placeholder='City'
            label='City'
            valueKey='city'
            object={office}
            update={(value, key) => onChange('office', value, key)}
            control={Input} width={12}
          />
          <Form.Field>
            <label>State</label>
            <Dropdown
              options={US_STATE_LIST}
              scrolling
              search
              value={office.state}
              onChange={(event, { value }) => onChange('office', value, 'state')}
              selection
            />
          </Form.Field>
          <TextFormField
            placeholder='55555'
            label='Zip'
            valueKey='postal_code'
            object={office}
            update={(value, key) => onChange('office', value, key)}
            control={Input} width={2}
          />
        </Form.Group>
        <Button type='submit' color="green" onClick={() => onSave()}>Save</Button>
      </Form>
    </Tab.Pane>
  )
}