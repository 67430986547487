import React from 'react';
import { Message } from 'semantic-ui-react';

export function ErrorDisplay(props) {
    if(!props.errors||props.errors.length<1) {
        return null
    }
    return   <Message
                onDismiss={props.handleDismissErrors}
                icon='warning'
                error
                header='We experienced an error when processing your last request.'
                list={props.errors.length>1?props.errors:null}
                content={props.errors.length<2?props.errors[0]:null}
  />

}
