
export function normalize(uuids, objectSource, key = 'uuid') {
    const matched = [];
    const unmatched = [];
    uuids.forEach(aUUID => {
       const aMatch = objectSource.filter(anObject => anObject[key] === aUUID)[0];
       if(aMatch){
           matched.push(aMatch);
       } else {
           unmatched.push(aUUID);
       }
    });
    return {matched,unmatched};
}