import React from 'react';
import { Grid, Icon, Button, Dropdown, Popup } from 'semantic-ui-react';
import './ToolbarCalendar.css';
import SelectionCalendarMonth from 'widgets/SelectionCalendar/SelectionCalendarMonth';
import TagsSelection from 'widgets/TagsSelection'

const ToolbarCalendar = (data) => (
  <Grid padded id="toolbar-calendar">
    <Grid.Column className="toolbar-section" width={16}>
      <Button content="Today" onClick={() => data.onNavigate('TODAY')} />
      <span style={{}} className="date-navigation">
        <Icon name="angle left" onClick={() => data.onNavigate('PREV')} />
        <Icon name="angle right" onClick={() => data.onNavigate('NEXT')} />
        <Popup 
          trigger={<span className="date-label">{data.label}<Icon name="angle down"/></span>}
          position="bottom center"
          on="click"
          className="pop-selector"
        >
          {/* <Grid>
            <Grid.Column> */}
                <SelectionCalendarMonth date={data.date} handleNavigate={(date) => data.onNavigate('', date, data.view)}/>
            {/* </Grid.Column>
            <Grid.Column>
              <Grid.Row divided className="year-container">
                <SelectionCalendarYear date={data.date} />
                <span className="go-to-today" onClick={() => data.onNavigate('TODAY')}>
                  Go to Today
                </span>
              </Grid.Row>
            </Grid.Column>
          </Grid> */}
        </Popup>
      </span>
      <Dropdown
        className="view-select"
        value={data.view}
        selection
        options={data.views.map(view => ({ key: view, text: data.messages[view].replace(/^\w/, c => c.toUpperCase()), value: view }))}
        onChange={(event, { value }) => data.onViewChange(value)}
      />
      <TagsSelection
        className="tags-select"
        selectedTags={data.selectedTags}
        onChange={(tags) => data.onChange(tags)}
        availableTags={data.availableTags}
      />
    </Grid.Column>
  </Grid>
)

export default ToolbarCalendar;