import React from 'react';
import './ComponentWrapper.css'

const ComponentWrapper = ({children, className="", ...props}) => (
  <div 
    className={`component-wrapper ${className.length > 0 ? className : ''}`}
    {...props}
  >
    {children}
  </div>
)

export default ComponentWrapper