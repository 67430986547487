import React, { Component } from 'react'
import  { Button, Form, Input, TextArea } from 'semantic-ui-react'
import { ColorPicker } from 'widgets/ColorPicker';

export class TagDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTag : props.selectedTag
        }
        this.bound_initizeData = this.initializeData.bind(this);
    }
    saveChanges(){
        this.props.onSaveTag(this.state.selectedTag)
    }
 
    /*
    static getDerivedStateFromProps(nextProps, prevState){
        if(!nextProps.selectedTag) {
            return prevState;
        }
        return {...prevState,
            selectedTag:nextProps.selectedTag
        }
    }
    */

   initializeData() {
    this.setState( {...this.state,
        selectedTag : this.props.selectedTag
    });
}


    render () {
        return (

                    <Form>
                    
    <Form.Group widths='equal'>
        <Form.Field 
            id='form-input-control-tag-name' 
            onChange={(event, {value}) => this.setState(
                    {...this.state, 
                        selectedTag:{...this.state.selectedTag,name:value}
                    }
                )
            } 
            control={Input} 
            label='Tag Name' 
            placeholder='Tag Name' 
            value={this.state.selectedTag.name}
        /><Form.Field>
           <label>Color</label>
        <ColorPicker onSelect={(color) => this.setState(
                    {...this.state, 
                        selectedTag:{...this.state.selectedTag,color}
                    })}
                    selectedColor={this.state.selectedTag.color}
                    />
        </Form.Field>
    </Form.Group>
    <Form.Field 
        id='form-textarea-control-tag-opinion'
        onChange={(event, {value}) => this.setState(
            {...this.state, 
                selectedTag:{...this.state.selectedTag,description:value}
            }
        )
        }  
        control={TextArea} 
        label='Description' 
        placeholder='Description'  
        value={this.state.selectedTag.description||''}
    />


                    {
                    this.state.selectedTag.uuid&&(
                            <Button content="Delete" color='red' icon="trash" labelPosition='right' onClick={() => {if(window.confirm('Do you really want to delete this Tag?'))this.props.onDeleteTag(this.state.selectedTag)}} />
                        )
                    } 
                    <Button positive icon='checkmark' labelPosition='right' content="Save" onClick={() => this.saveChanges()} />
</Form>
        )
    }
}