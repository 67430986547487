import { connect } from 'react-redux';
import {Tags} from 'components/Tags/Tags';
import { saveTag, deleteTag, fetchTags, selectTag, fetchSelectedTag } from 'actions/TagActions'

const mapStateToProps = (state, ownProps) => {
    return {
        tags: state.tags.tags,
        loading: state.tags.loading,
        professional: state.session.professional,
        selectedTag: state.tags.selectedTag,
        saveTagErrors: state.tags.saveTagErrors,
        loading_selected_tag: state.tags.loading_selected_tag,
        communicationMessageTemplates:state.metaData.communicationMessageTemplates,
        communicationTypes:state.metaData.communicationTypes,
        occasions:state.metaData.occasions
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleSaveChanges: (tag) => dispatch(saveTag(tag)),
        handleSelectTag: (tag) => dispatch(tag.uuid?fetchSelectedTag(tag):selectTag(tag)),
        fetchTags:(professional) => dispatch(fetchTags(professional)),
        handleDeleteTag: (tag) => dispatch(deleteTag(tag))
    }
}

const TagsContainer = connect(mapStateToProps, mapDispatchToProps)(Tags);


export {TagsContainer}