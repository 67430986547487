import React, { Component } from 'react';
import { connect } from 'react-redux';
import {NavigationTabs} from 'components/NavigationTabs/NavigationTabs';
import { withRouter } from 'react-router'
import { logout, restartTour, ping, unsavedChanges } from 'actions/SessionActions';
import { changeProfessional } from '../../actions/SessionActions';
import { resetMICToken } from 'actions/MICActions';
import { reloadSyncSettings } from '../../actions/SettingsActions';
import { reloadIntegrationSettings } from '../../actions/IntegrationSettingsAction';

function mapStateToProps(state) {
    const props = {
        unsavedChanges:state.session.hasUnsavedChanges,
        professional:state.session.professional,
        possibleProfessionals:state.session.possible_professionals
    };
    return props;
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onLogOut:() => dispatch(logout()),
        onRestartTour: () => dispatch(restartTour()),
        onPing: () => dispatch(ping()),
        onClearUnsavedChanges: () => dispatch(unsavedChanges(false)),
        changeProfessional: (professional) => {
            let action = changeProfessional(professional);
            dispatch(action)
            dispatch(resetMICToken());
            dispatch(reloadIntegrationSettings(professional));
        }
     }
}




class NavigationTabsContainerComponent extends Component {

    componentDidMount() {
        this.pingInterval = setInterval(() => this.props.onPing(), 10*60*1000)
    }

    componentWillUnmount() {
        if(this.pingInterval) {
            clearInterval(this.pingInterval);
        }
    }

    onClick(path) {
        if(!this.props.unsavedChanges||window.confirm('You have unsaved changes. By navigating away you will lose them.  Do you want to continue?')) {
            this.props.history.push(path)
            this.props.onClearUnsavedChanges()
        }
    }

    render() {
        let activeItem;
        switch(this.props.history.location.pathname){
            case '/calendar':
                activeItem = 'calendar'
                break;
            case '/clients':
                activeItem = 'clients'
                break;
            case '/tags':
                activeItem = 'tags'
                break;
            case '/smarttags':
                    activeItem = 'smarttags'
                    break;
            case '/settings':
                activeItem = 'settings'
                break;
            case '/campaigns':
                activeItem = 'campaigns'
                break;
            default:
                activeItem = 'calendar'
        } 
        return (
            <NavigationTabs 
                onLogOut={() => this.props.onLogOut()} 
                activeItem={activeItem} 
                onRestartTour={() => {this.props.history.push('/calendar');this.props.onRestartTour()}} 
                onClick={path => this.onClick(path)}
                professional={this.props.professional}
                possibleProfessionals={this.props.possibleProfessionals}
                changeProfessional={this.props.changeProfessional}
                />
            );
    }
}


const NavigationTabsContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(NavigationTabsContainerComponent))

export {NavigationTabsContainer}
