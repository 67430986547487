import React from 'react'
import { Dropdown, Icon } from 'semantic-ui-react'
import { requestForEndpointAndPayload } from 'App';
import _ from 'lodash'


const options = clients => clients.map((client) => {
    return { key: client.uuid, text: `${client.family_name||''}${client.family_name?', ':''}${client.given_name||' -/-'}${client.company_name?' ('+client.company_name+') ':''}`, value: client.uuid }
})

export class ClientsSelection extends React.Component{
  constructor(props) {
    super(props)
    this.state ={
      searchQuery:'',
      availableClients:props.availableClients||[],
      selectedClients:props.selectedClients||[],
      loading:true
      }
  }

   componentDidMount() {
     //if(!this.props.availableClients || this.props.availableClients.length === 0) {
       //this.props.handleFetchClients(this.props.professional,1,1000,'',[])
     //}
     this.fetchSelectedClients(this.props.selectedClients);
   }

  fetchSelectedClients(clientIds) {

    if(!clientIds||clientIds.length<1) {
      this.setState({...this.state,selectedClients:[], loading:false});
      return;
    }
    const request = requestForEndpointAndPayload('get',{
      client_ids:  clientIds,
      client_properties:['given_name','family_name','company_name','uuid']
  })


  fetch(request.url,request).then(response => response.json()).then(
    data => {
      this.setState({...this.state,selectedClients:data.clients, loading:false})});
  }

  fetchClients(searchTerm) {
    if(!searchTerm) {
      this.setState({...this.state,searchQuery:searchTerm,availableClients:[]})
      return;
    }
    this.setState({...this.state,searchQuery:searchTerm})
    const request = requestForEndpointAndPayload('query',{
      clients:  [
                    {
                        and : [
                            ["professional","=",this.props.professional],
                            {
                                or: [
                                    ["given_name", "like", `*${searchTerm}*`],
                                    ["family_name", "like", `*${searchTerm}*`],
                                    ["company_name", "like", `*${searchTerm}*`],
                                ]
                            }
                        ],
                        page : 1,
                        page_size: 100
                    }
                ],
      client_properties:['given_name','family_name','company_name','uuid']
  })
    fetch(request.url,request).then(response => response.json()).then(
      data => {
        this.setState({...this.state,availableClients:data.clients})});

  }

  render() {
    let availableClients = [];
    const rawClients = _.unionWith(this.state.availableClients, this.state.selectedClients, _.isEqual);
    if(rawClients) {
      availableClients = rawClients.sort((a,b) =>{
        const aValue = a.family_name||a.given_name;
        const bValue = b.family_name||b.given_name;
        if(!aValue || aValue === '') {
            return !bValue||bValue === ''?0:-1;
        }
        return aValue.localeCompare(bValue)
      });
    }
    if(this.state.loading) {
      return <Icon name="spinner" loading/>
    } else {
        return <Dropdown 
        searchQuery={this.state.searchQuery}
        onSearchChange={event => this.fetchClients(event.target.value)}
        closeOnChange
        search 
        placeholder='Clients' 
        value={this.props.selectedClients} 
        fluid 
        multiple 
        selection 
        noResultsMessage={this.state.searchQuery?'No results found.':'Type to select by name.'}
        options={options(availableClients)} 
        onChange={(event, {value}) => {
          this.props.onChange?this.props.onChange(value):console.log(value)
          this.setState({...this.state,availableClients:[],searchQuery:''})
          this.fetchSelectedClients(value)}
        }
        renderLabel={this.props.renderLabel}
        />
    }
  }
}
