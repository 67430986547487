export const SET_REMINDER_OFFSETS = "SET_REMINDER_OFFSETS";
export const SET_COMMUNICATION_MESSAGE_TEMPLATES = "SET_COMMUNICATION_MESSAGE_TEMPLATES";
export const SET_COMMUNICATION_TYPES = "SET_COMMUNICATION_TYPES";
export const SET_OCCASIONS = "SET_OCCASIONS";
export const SET_DATE_LOCATIONS = "SET_DATE_LOCATIONS";


export function setCommunicationTypes(communicationTypes) {
    return {
        type : SET_COMMUNICATION_TYPES,
        payload: communicationTypes
    }
}

export function setReminderOffsets(reminderOffsets) {
    return {
        type : SET_REMINDER_OFFSETS,
        payload: reminderOffsets
    }
}

export function setCommunicationMessageTemplates(templates) {
    return {
        type : SET_COMMUNICATION_MESSAGE_TEMPLATES,
        payload: templates
    }
}

export function setOccasions(occasions) {
    return {
        type : SET_OCCASIONS,
        payload: occasions
    }
}


export function setDateLocations(dateLocations) {
    return {
        type : SET_DATE_LOCATIONS,
        payload: dateLocations
    }
}
