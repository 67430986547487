import React from 'react';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';

export default function Wrapper(props) {
    const displayElement = props.loading?(<Dimmer active={props.loading} page>
    <Loader />
    </Dimmer>):props.children;
    return (<Segment {...props} style={{height: '100%', ...props.style}}>
    {/*<div style={{textAlign:'left',marginBottom:'10px'}}><Icon name={props.icon} size='large' circular/><span style={{fontSize:'20px'}}></span></div>*/}
    {displayElement}
    </Segment>)
}