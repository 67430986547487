import { SETUP, SETUP_FULLFILLED, SETUP_FAILED, VALIDATE_SCOPE, VALIDATE_SCOPE_FULLFILLED } from "actions/SetupActions";

export function SetupReducer(state = {}, action) {
    let {type} = action;
    switch(type) {
        case SETUP:
            return {...state, loading : true}
        case SETUP_FULLFILLED:
            return {...state, loading:false, completed:true}
        case SETUP_FAILED:
            return {...state, loading:false, completed:false}
        case VALIDATE_SCOPE:
            return state;
        case VALIDATE_SCOPE_FULLFILLED:
            return {...state, scope: action.payload.scope}
        default:
        return state;
    }
}