import React from 'react';
import { Grid, Dropdown, Pagination, Icon } from 'semantic-ui-react';
import './PaginationFooter.css'

export const PaginationFooter = ({page_size, page, total_item_count, total_page_count, handlePageSizeChange, handlePaginationChange}) => (
  <Grid verticalAlign="middle" className="footer-pagination" stackable>
    <Grid.Column className="footer-element">
      <div className="select-per-page">
        <div className="wrapper-text">Show</div>
        <Dropdown
          className="item-limit-selection"
          upward
          onChange={(e, d) => handlePageSizeChange(d.value)}
          options={[
            { key: 5, value: 5, text: 5 },
            { key: 10, value: 10, text: 10 },
            { key: 25, value: 25, text: 25 },
            { key: 50, value: 50, text: 50 },
          ]}
          value={page_size}
        />
        <div className="wrapper-text">items per page</div>
      </div>
    </Grid.Column>
    <Grid.Column className="footer-element" width={3}>
      <p>
        {(page_size * page) + 1 - page_size} - {(page_size * page) < total_item_count ? (page_size * page) : total_item_count} of {total_item_count} results
      </p>
    </Grid.Column>
    <Grid.Column className="footer-element">
      <Pagination
        className="pagination-selection"
        activePage={page}
        totalPages={total_page_count}
        onPageChange={(e, d) => handlePaginationChange(d.activePage)}
        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
        prevItem={{ content: <Icon name='angle left' />, icon: true }}
        nextItem={{ content: <Icon name='angle right' />, icon: true }}
      />
    </Grid.Column>
  </Grid>
)