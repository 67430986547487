import React from 'react'
import { DataTable } from './DataTable';
import moment from 'moment'

const ClientTable = (props) => {
    let clientOrdering = (a, b) => a.complete_name.toLowerCase().localeCompare(b.complete_name.toLowerCase());
    return <DataTable 
                id="clients"
                columnDescription={[
                    {
                        key:'complete_name',
                        header:'Name',
                        sorting:clientOrdering
                    },
                    {
                        key:'email',
                        header:'Email',
                        sorting:(a, b) => {
                            return a.email.toLowerCase().localeCompare(b.email.toLowerCase())
                        }
                    },
                    {
                        key:'newest_date',
                        header:'Next Appointment',
                        format:(date) => date?moment(date.start_timestamp).format('lll'):'',
                        sorting:(a, b) => {
                            return moment(b.newest_date.start_timestamp).valueOf() - moment(a.newest_date.start_timestamp).valueOf()
                        }
                    }

                ]}
                initialSorting= {{
                    sorting: clientOrdering,
                    asc:true
                }}
                dataRows={props.clients}
                pageSize={props.pageSize||5}
            />
}

export default ClientTable