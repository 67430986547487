import { connect } from 'react-redux';
import {Login} from 'components/Login/Login';
import {login, changePassword, changeUsername, setLoginErrorMessage, changeScope} from 'actions/SessionActions';


const mapStateToProps = (state, ownProps) => {

    return {
        username:state.session.username,
        password:state.session.password,
        message:state.session.loginErrorMessage,
        loading:state.session.loading,
        scope:state.session.scope,
        defined_scope:state.session.defined_scope
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onLogin: (username, password) => {
            if(!username || !password) {
                dispatch(setLoginErrorMessage("Username or Password must not be empty!"))
            } else {
                dispatch(login(username, password))
            }
        },
        onChangeScope: (scope) => dispatch(changeScope(scope)),
        onChangeUsername: (username) => dispatch(changeUsername(username)),
        onChangePassword: (password) => dispatch(changePassword(password))
    }
}


const LoginContainer = connect(mapStateToProps,mapDispatchToProps)(Login);

export {LoginContainer};