import { SET_COMMUNICATION_TYPES, SET_COMMUNICATION_MESSAGE_TEMPLATES, SET_REMINDER_OFFSETS, SET_OCCASIONS, SET_DATE_LOCATIONS } from 'actions/MetaDataActions';

export const MetaDataReducer = (state = { 
    communication_message_templates:[],
    reminder_offsets:[],
    communication_types:[],
    occasions:[],
    dateLocations:[]
}, action) => {
    let {payload, type} = action
    switch(type) {
        case SET_COMMUNICATION_MESSAGE_TEMPLATES:
            return {...state,communicationMessageTemplates:payload}
        case SET_COMMUNICATION_TYPES:
            return {...state,communicationTypes:payload}
        case SET_REMINDER_OFFSETS:
            return {...state,reminderOffsets:payload}
        case SET_OCCASIONS:
            return {...state,occasions:payload}
        case SET_DATE_LOCATIONS:
                return {...state,dateLocations:payload}
        default:
            return state;
    }
}