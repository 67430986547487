import { combineReducers } from 'redux';
import ClientReducer from './ClientReducer';
import TagReducer from './TagReducer';
import { routerReducer } from 'react-router-redux';
import SessionReducer from './SessionReducer';
import { MetaDataReducer } from './MetaDataReducer';
import { SettingsReducer } from './SettingsReducer';
import CalendarReducer from './CalendarReducer';
import { LOGOUT } from 'actions/SessionActions';
import { MICReducer } from './MICReducer';
import { SetupReducer } from './SetupReducer';
import { PasswordReducer } from './PasswordReducer';
import SmartTagReducer from './SmartTagReducer';
import CampaignReducer from './CampaignReducer';
import IntegrationSettingsReducer from './IntegrationSettingsReducer';


const RootReducer = (state, action) => {
    if (action.type === LOGOUT) {
      window.sessionStorage.clear();
      const { routing } = state
      state = { routing } 
    }
    return appReducer(state, action)
  }

  const appReducer = combineReducers({
    appointments:CalendarReducer,
    clients:ClientReducer,
    tags:TagReducer,
    campaigns:CampaignReducer,
    smartTags:SmartTagReducer,
    routing: routerReducer,
    session:SessionReducer,
    metaData:MetaDataReducer,
    settings:SettingsReducer,
    mic:MICReducer,
    setup:SetupReducer,
    password:PasswordReducer,
    integrationSettings:IntegrationSettingsReducer
    })

    export default RootReducer