import { SELECT_CATEGORY, FETCH_CATEGORIES_FULLFILLED, SAVE_SMART_TAG_FULLFILLED, SAVE_SMART_TAG, FETCH_CLIENTS_FOR_CATEGORY_FULLFILLED } from '../actions/SmartTagActions';

const SmartTagReducer = (state = {categories:[]}, action) => {
    const {type} = action;
    switch(type) {
        case SAVE_SMART_TAG:
            return {...state,loading_selected_smart_tag:true};
        case SAVE_SMART_TAG_FULLFILLED:
            const smartTag = action.payload.smartTag;
            if(!smartTag) {
                return {...state,loading_selected_smart_tag:false};
            }
            const category = state.categories.filter(category => category.name === smartTag.category)[0];
            const categories = state.categories.map(currentCategory => {
                if(currentCategory.name === category.name) {
                    return {...currentCategory, smartTag};
                }
                return currentCategory;
            })
            return {...state, 
                selectedCategory:{...category,smartTag},
                loading_selected_smart_tag:false,
                categories
            }
        case FETCH_CATEGORIES_FULLFILLED:
        
            return {
                ...state,
                categories:action.payload.categories,
                selectedCategory:state.selectedCategory||action.payload.categories[0]
            }
        case SELECT_CATEGORY:
        case FETCH_CLIENTS_FOR_CATEGORY_FULLFILLED:
            {
            return {
                ...state,
                selectedCategory:action.payload.category
            }
        }
        default:
            return state;
    }
}

export default SmartTagReducer;