import React, { Component } from 'react'
import Wrapper from 'widgets/Wrapper';
import ComponentWrapper from 'widgets/ComponentWrapper/ComponentWrapper';
import { Tab } from 'semantic-ui-react'
import LoadingIndicator from 'widgets/LoadingIndicator';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import validateEmail from 'utils/emailValidation';
import { accountTab, profileTab, communicationSettingsTab, importTab, bookingTab, reminderTab, aboutTab } from './tabs'
import { SyncTab } from './syncTab';
import { MicrosoftSyncTab } from './microsoftSyncTab';
import { ZoomSettingTab } from './ZoomSettingTab';
import { hasRequiredFeature } from '../../widgets/FeatureConditional';
import { ZOOM_INTEGRATION } from '../../constants/Features';


export class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.bound_initializeData = this.initializeData.bind(this);
    }
    
    componentDidMount() {
        document.addEventListener('professionalChanged',  this.bound_initializeData, false);
        this.props.onFetchSettings();
    }

    componentWillUnmount() {
        document.removeEventListener('professionalChanged', this.bound_initializeData);
    }

    initializeData() {
        //console.log(this.state);
        //this.setState({});
        this.props.onFetchSettings();
    }
    

    saveSettings(rawSettings) {
        if(rawSettings.user.password && rawSettings.user.password.length < 8) {
            alert('Password must be at least 8 characters long')
            return;
        }
        if(rawSettings.office.email&&!validateEmail(rawSettings.office.email)) {
            alert('Invalid account email address.')
            return;
        }
        if(rawSettings.professional.email&&!validateEmail(rawSettings.professional.email)) {
            alert('Invalid profile email address.')
            return;
        }
        if(rawSettings.user.password === rawSettings.user.verify_password) {
            this.props.onSaveSettings(rawSettings);
            let newSettings = {...rawSettings};
            newSettings.user.password = null;
            newSettings.user.verify_password = null;
            this.setState({...this.state, newSettings});
            this.props.flagAsUnsavedChanges(false);
        }
        else {
            alert('Passwords don\'t match');
        }
    }

    createClonedState(objectKey, value, key) {
        let object = {...this.state.settings[objectKey]};
        object[key] = value;
        let newSettings = {...this.state.settings}
        newSettings[objectKey] = object;
        return {...this.state, settings:newSettings};
    }

    update(objectKey, value, key) {
        this.setState({...this.createClonedState(objectKey, value, key)});
        this.props.flagAsUnsavedChanges(true);
    }

    static getDerivedStateFromProps(props, state) {

        if (!state.settings && props.settings && !props.loading) {
            return {...state,settings:props.settings}
        }
        if(state.settings && props.settings && state.settings.professional.uuid !== props.settings.professional.uuid) {
            return {...state,settings:props.settings}
        }
        return state;
    }

    render () {
        if (!this.state.settings) {
            return <LoadingIndicator />
        }
        return (
            <ComponentWrapper>
                <Wrapper icon="setting" loading={this.props.loading}>
                <Tab 
                    activeIndex={this.state.tagTabIndex} 
                    onTabChange={(event, data) => this.setState({...this.state,tagTabIndex:data.activeIndex})}
                    menu={{ fluid: true, vertical: true, tabular: 'right' }} 
                    panes={tabs(
                        this.state.settings.office, 
                        this.state.settings.company, 
                        this.state.settings.user, 
                        this.state.settings.professional, 
                        this.props.communicationTypes,
                        this.props.reminderOffsets,
                        this.props.communicationMessageTemplates,
                        this.props.occasions,
                        (objectKey, value, key) => this.update(objectKey, value, key), 
                        () => this.saveSettings(this.state.settings),
                        this.props
                    )} 
                    />

                </Wrapper>       
            </ComponentWrapper>
        )
    }
}


const tabs = (office, company, user, professional, communicationTypes, reminderOffsets, communicationMessageTemplates, occasions, onChange, onSave, props) => {
    
    let settingTabs = [
                        { menuItem: 'Account Profile', render: () =>  accountTab(company, office, onChange, onSave)},
                        { menuItem: 'User Profile', render: () => profileTab(user, professional, onChange, onSave)},
                        { menuItem: 'Communication Settings', render : () => communicationSettingsTab(company, office, professional, communicationMessageTemplates, occasions, reminderOffsets, communicationTypes, onChange, onSave)},
                        { menuItem: 'Google Sync', render: () => <SyncTab 
                                                                    googleSyncSettings={props.googleSyncSettings} 
                                                                    googleCalendarSyncs={props.googleCalendarSyncs} 
                                                                    at={props.at} 
                                                                    scope={props.scope}
                                                                    refreshSyncSettings={props.refreshSyncSettings}
                                                                    handleError={props.handleError}
                                                                    available={props.microsoftCalendarSyncs.length===0}
                                                                    onToggleVisibilityOfGoogleCalendar={props.onToggleVisibilityOfGoogleCalendar}
                                                                    />},
                        { menuItem: 'Microsoft/Outlook Sync', render: () => <MicrosoftSyncTab 
                                                                    microsoftSyncSettings={props.microsoftSyncSettings} 
                                                                    microsoftCalendarSyncs={props.microsoftCalendarSyncs} 
                                                                    at={props.at} 
                                                                    scope={props.scope}
                                                                    refreshSyncSettings={props.refreshSyncSettings}
                                                                    handleError={props.handleError}
                                                                    available={props.googleCalendarSyncs.length===0}
                                                                    onToggleVisibilityOfMicrosoftCalendar={props.onToggleVisibilityOfMicrosoftCalendar}
                                                    />}];
                                                    if(hasRequiredFeature(props.features,ZOOM_INTEGRATION)) {
    settingTabs = settingTabs.concat({ menuItem: 'Zoom Integration', render: () => <ZoomSettingTab 
                                                zoomSettings={props.zoomSettings} 
                                                at={props.at} 
                                                scope={props.scope}
                                                refreshSyncSettings={props.refreshSyncSettings}
                                                available={props.zoomSettings.length===0}
                                                handleError={props.handleError}
                                                />}) 
                                                    }
    return settingTabs.concat(                                                                                           
                            [{ menuItem: 'Initial Import', render: () => importTab(professional, (professional, csvDataURL) => props.onImport(professional, csvDataURL), props) },
                            { menuItem: 'Online Booking Settings', render: () => bookingTab(professional,onChange,onSave, props.bookingAPIKey)},
                            { menuItem: 'Signatures', render: () => reminderTab(professional, onChange, onSave) },
                            { menuItem: 'About', render: () => aboutTab()}]
                            )
}