import { RELOAD_INTEGRATION_SETTINGS_FULLFILLED } from "../actions/IntegrationSettingsAction";


const IntegrationSettingsReducer = (state = {}, action) => {
    let {payload, type} = action
    switch(type) {
        case RELOAD_INTEGRATION_SETTINGS_FULLFILLED:
            return payload;
        default:
            return state;
    }
}

export default IntegrationSettingsReducer;
        