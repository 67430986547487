import {Observable} from 'rxjs'
import { FETCH_ALL_CAMPAIGNS, fetchAllCampaignsFullfilled, SAVE_SELECTED_CAMPAIGN, saveSelectedCampaignFullfilled, DELETE_SELECTED_CAMPAIGN, deleteSelectedCampaignFullfilled } from '../actions/CampaignActions';
import { requestForEndpointAndPayload } from '../App';
import { handleAjaxError } from './sessionEpic';




export function fetchAllCampaignsEpic(action$) {
    return action$.ofType(FETCH_ALL_CAMPAIGNS)    
        .switchMap(({payload: {professional}}) => {

        return Observable.ajax(requestForEndpointAndPayload('query',{

            campaigns:  [
                {
                    and : [
                        ["professional","=",professional]
                    ]
                }
            ],
            tags:  [
                {
                    and : [
                        ["professional","=",professional]
                    ]
                }
            ],
            categories:  [
                {
                    and : [
                        ["occasions","contains","CAMPAIGN"]
                    ]
                }
            ],
            holiday_records:    [
                {
                    and :   [
                        ["observed","=",false]
                    ]
                }
            ],
            communication_message_templates: [
                {
                    and : [
                        ["occasion","=","CAMPAIGN"],
                        ["status","=","ACTIVE"]
                    ]
                }
            ]
})
    )
.map(({response}) => {
    return fetchAllCampaignsFullfilled(
            response.campaigns,
            response.tags,
            response.categories,
            response.holiday_records,
            response.communication_message_templates[0]);
}).catch((e) => {
    console.log("error from server", e)
    return Observable.concat([fetchAllCampaignsFullfilled([],[],[],[]), handleAjaxError(e)])
    //return Observable.of(setLoginErrorMessage('Could not find user with username and/or password!'))
})
});
}

export function saveSelectedCampaignsEpic(action$) {
    return action$.ofType(SAVE_SELECTED_CAMPAIGN)    
        .switchMap(({payload: {selectedCampaign}}) => {
            
        return Observable.ajax(requestForEndpointAndPayload('put',{
            campaigns: [selectedCampaign]
})
    )
.map(({response}) => {
    return saveSelectedCampaignFullfilled(
            response.campaigns[0]);
}).catch((e) => {
    console.log("error from server", e)
    return Observable.concat([saveSelectedCampaignFullfilled(selectedCampaign), handleAjaxError(e)])
    //return Observable.of(setLoginErrorMessage('Could not find user with username and/or password!'))
})
});
}

export function deleteSelectedCampaignsEpic(action$) {
    return action$.ofType(DELETE_SELECTED_CAMPAIGN)    
        .switchMap(({payload: {deletedCampaign}}) => {

        return Observable.ajax(requestForEndpointAndPayload('delete',{
            campaign_ids: [deletedCampaign]
})
    )
.map(({response}) => {
    return deleteSelectedCampaignFullfilled(
            response.campaign_ids[0]);
}).catch((e) => {
    console.log("error from server", e)
    return Observable.concat([deleteSelectedCampaignFullfilled([]), handleAjaxError(e)])
    //return Observable.of(setLoginErrorMessage('Could not find user with username and/or password!'))
})
});
}