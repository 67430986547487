
import { RESET_PASSWORD, RESET_PASSWORD_FULLFILLED, REQUEST_PASSWORD_RESET, REQUEST_PASSWORD_RESET_FULLFILLED, RESET_PASSWORD_FAILED } from 'actions/PasswordActions';

export const PasswordReducer = (state = {}, action) => {
    const { type } = action; 
    switch(type) {
        case RESET_PASSWORD:
            return {...state, loading:true, failed:undefined}
        case RESET_PASSWORD_FULLFILLED:
            return {...state, loading:undefined, completed:true, failed:undefined}
        case RESET_PASSWORD_FAILED:
            return {...state, loading:undefined, completed:true, failed:true}       
        case REQUEST_PASSWORD_RESET:
            return {...state, loading:true}
        case REQUEST_PASSWORD_RESET_FULLFILLED:
            return {...state, loading:undefined, completed:true}
        default:
            return state;
    }
}