import {Observable} from 'rxjs'
import { RESET_PASSWORD, resetPasswordFullfilled, resetPasswordFailed } from 'actions/PasswordActions';
import { BASE_URL } from 'App';

export function resetPasswordEpic(action$, store) {
    return action$.ofType(RESET_PASSWORD)
    .switchMap(({payload}) => Observable.ajax(
            {
                url: BASE_URL+`/pwd/${store.getState().session.scope}/change`,
                method:'POST',
                responseType:'json',
                body:`t=${encodeURIComponent(payload.token)}&pwd=${encodeURIComponent(payload.newPassword)}`
            }

        )
    ).map(result => resetPasswordFullfilled())
    .catch((e) => {
        console.log('resetPasswordEpic',e)
        return Observable.of(resetPasswordFailed());
    })

}