import { connect } from 'react-redux';
import { Campaigns } from '../../components/Campaigns/Campaigns';
import { fetchAllCampaigns, saveSelectedCampaign, deleteSelectedCampaign, selectCampaign } from '../../actions/CampaignActions';

const mapStateToProps = (state, ownProps) => {
    return {
        categories:state.campaigns.categories,
        campaigns:state.campaigns.campaigns,
        tags:state.campaigns.tags,
        professional:state.session.professional,
        office:state.session.offices[0],
        selectedCampaign:state.campaigns.selectedCampaign||{last_processing_date:new Date(),categorie_names:[],office:state.session.offices[0],professional:state.session.professional},
        holidayRecords:state.campaigns.holiday_records,
        fetchingCampaigns:state.campaigns.fetching,
        template:state.campaigns.template,
        saving:state.campaigns.saving,
        deleting:state.campaigns.deleting
   }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        selectCampaign:(campaign) => dispatch(selectCampaign(campaign)),
        fetchAllCampaigns:(professional) => dispatch(fetchAllCampaigns(professional)),
        saveSelectedCampaign:(selectedCampaign) => dispatch(saveSelectedCampaign(selectedCampaign)),
        deleteSelectedCampaign:(selectedCampaign) => dispatch(deleteSelectedCampaign(selectedCampaign))
    }
}

const CampaignsContainer = connect(mapStateToProps, mapDispatchToProps)(Campaigns);


export {CampaignsContainer} ;

