import React from 'react'
import { Dimmer, Loader, Button, Form, Grid, Header, Image, Message, Segment, Popup, Icon } from 'semantic-ui-react'
import './ChangePassword.css';
import logo from 'assets/logo.png'
import { Footer } from 'components/Footer/Footer';
import { scorePassword } from 'utils/passwordUtils';

export class ChangePassword extends React.Component {
        constructor(props) {
            super(props);
            this.state = {}
        }

  labelForInput(fieldName, validationError, error, errorHelp) {
    let style = {fontWeight:'bold'};
    if(error) {
      style.color = '#d55';
    }
    return <label style={style}>{fieldName+(error?" "+validationError:'')} {error&&errorHelp}</label>;
  }

        changeVerifiedPassword(newVerifiedPassword) {
            const verifiedPasswordFailed = this.state.newPassword!==newVerifiedPassword;
            this.setState({...this.state,verifiedPasswordFailed,newVerifiedPassword})
        }

        changePassword(newPassword) {
            
            const passwordFailed = newPassword&&scorePassword(newPassword)<64
            const newVerifiedPassword = newPassword?this.state.newVerifiedPassword:''
            const verifiedPasswordFailed = !newVerifiedPassword&&newVerifiedPassword!==newPassword;
            this.setState({...this.state, verifiedPasswordFailed, newPassword, passwordFailed, newVerifiedPassword})
        }

        render() {
            const { loading, scope, change_completed, failed } = this.props;
            return (
              <div className='login-form'>
                <Dimmer active={loading}>
                  <Loader />
                </Dimmer>
                  <Grid
                    style={{ height: '100%' }}
                    verticalAlign='middle'
                    centered={true}
                  >
                    <Grid.Column style={{ maxWidth: 450 }}>
        
                      <Header as='h1' color='grey' textAlign='center'>
                        <Image src={logo} style={{width:'100px'}}/>
                      </Header>
                      
                      {failed&&<Message
                                negative
                                icon='exclamation'
                                header='This Link has expired'
                                content='Please start the process again or contact customer support.'
                            />}
                    {!change_completed&&!failed&&<Form size='large'>
                        <Segment >
                        <Header as='h2' color='grey' textAlign='center'>Set New Password</Header>
                          <Form.Input
                            label={this.labelForInput("New Password*", " - insufficient complexity", this.state.passwordFailed, <Popup trigger={<Icon name="help circle" />} content='A password should consist of characters, numbers and special characters (!@$%^&amp;*()' />)}
                            fluid
                            error={this.state.passwordFailed}
                            icon={!this.state.newPassword || this.state.passwordFailed ? 'lock' : 'check'}
                            iconPosition='left'
                            placeholder='Password'
                            name="password"
                            type='password'
                            value={this.state.newPassword}
                            onChange={(e, {value}) => this.changePassword(value)}
                          />
                          <Form.Input
                            label={this.labelForInput("Verify New Password*", " does not match new Password", this.state.verifiedPasswordFailed)}
                            fluid
                            error={this.state.verifiedPasswordFailed}
                            icon={!this.state.newVerifiedPassword || this.state.verifiedPasswordFailed ? 'lock' : 'check'}
                            iconPosition='left'
                            placeholder='Verify Password'
                            name="verify_password"
                            type='password'
                            value={this.state.newVerifiedPassword}
                            onChange={(e, {value}) => this.changeVerifiedPassword(value)}
                          />
                         {/*  <div style={{margin: '0 auto',marginBottom:'10px',width:'75%'}}>
                          <ReCAPTCHA
                            sitekey="6Le94GoUAAAAAPLtYWi6IYz7zM8H0ioA5IACCSKQ"
                            onChange={(arg) => console.log('captcha', arg)}
                          /></div> */}
        
                        <Button 
                            disabled={(!this.state.newPassword || this.state.passwordFailed) || (!this.state.newVerifiedPassword || this.state.verifiedPasswordFailed)} 
                            color='green' 
                            fluid 
                            size='large' 
                            onClick={() => this.props.resetPassword(tokenFromHash(window.location.hash),this.state.newPassword)}>
                                Sign Up
                        </Button>
                          
                          
                        </Segment>
                      </Form>}
                      {!failed&&change_completed&&<Message
                        icon='inbox'
                        header='Success'
                        content={<div><div>Your password was successfully changed!</div>
                          <a href={`https://${scope}.mojo.accountants`}>Click here to Login</a>
                        </div>}
                      />
                      }
                      <Footer />
                    </Grid.Column>
                  </Grid>
                
                </div>
            )
          }
    
}


function tokenFromHash(hash) {
    if(hash&&hash.startsWith('#')) {
      return hash.substr(1)
    }
    return hash;
  }
