import React from 'react'
import { Header, Table, Label, Icon, Tab, Button, Image, Dimmer, Segment, Message, Checkbox } from 'semantic-ui-react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import moment from 'moment';
import logo from 'assets/logoW.png'
import { HelpLink } from 'widgets/HelpLink/HelpLink';


const SYNC_BASE_URL = `https://sync.${window.location.host.includes('.dev.')?'dev.':''}mojo.accountants/cgi-bin/WebObjects/sync.woa`;
//const SYNC_BASE_URL = `http://127.0.0.1:4444/cgi-bin/WebObjects/sync.woa`;


export class SyncTab extends React.Component{
    
    constructor(props) {
        super(props);
        this.messageHandler = (event) => {
            if(event.data&&event.data.startsWith&&event.data.startsWith('{')) {
                try{
                    const data = JSON.parse(event.data)
                    if(data.channel!=="GOOGLE_OAUTH_RESPONSE") {
                        return;
                    }
                    if(data.result==='failed') {
                        this.props.handleError(data.details)
                    }
                    this.props.refreshSyncSettings(event)
                } catch(e){
                    console.log(e)
                    return;
                }                
            }
            
        };
        this.state = {};
    }
    
    terminateGSync() {
        if(window.confirm('Do you really want to terminate Google sync?')){
            fetch(`${SYNC_BASE_URL}/google/oauth/terminate?at=${this.props.at}&scope=${this.props.scope}`)
                .then(
                    (response) => {
                        if (response.status !== 200) {
                            this.props.handleError("Terminating failed. Please try again later or contact customer support.")
                            return;
                        }
                        this.props.refreshSyncSettings()
                    }).catch(err => 
                        this.props.handleError("Terminating failed. Please try again later or contact customer support."));

        }
    }

    suspendGSync() {
        if(window.confirm('Do you really want to suspend Google sync?')){
            fetch(`${SYNC_BASE_URL}/google/oauth/suspend?at=${this.props.at}&scope=${this.props.scope}`)
                .then(
                    (response) => {
                        if (response.status !== 200) {
                            this.props.handleError("Suspending failed. Please try again later or contact customer support.")
                            return;
                        }
                        this.props.refreshSyncSettings()
                    }).catch(err => {
                        console.log(err)
                        return this.props.handleError("Suspending failed. Please try again later or contact customer support.")}
                );
        }
    }

    componentDidMount() {
        this.setState({...this.state, updateInterval:window.setInterval(() => this.props.refreshSyncSettings(), 30*1000)})
        window.addEventListener("message", this.messageHandler);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.messageHandler);
        window.clearInterval(this.state.updateInterval);
    }

   

    render() {
        const googleSync = this.props.googleSyncSettings[0];
        return (
        <Tab.Pane>

                <Header as='h3'>
        {googleSync&&<Icon loading={googleSync.status==='ACTIVE'} size='big' name={googleSync.status==='ACTIVE'?'sync alternate':'pause'} color={googleSync.status==='ACTIVE'?'green':'yellow'}/>} Google Sync 
                <HelpLink topic="googlesync" /></Header>
                {googleSync&&<React.Fragment><span>{googleSync.status==='ACTIVE'?<span>
      syncing since {moment(googleSync.creation_date).format('lll')}<br />
    {googleSync.last_contact_sync?`synced Contacts ${moment(googleSync.last_contact_sync).fromNow()}`:<span>initial Contacts sync in progress<Icon loading name='circle notch' /></span>}
    </span>:<span>Syncing has been suspended</span>}</span>
            {
                this.props.googleCalendarSyncs&&this.props.googleCalendarSyncs[0]&&<Dimmer.Dimmable as={Segment} blurring dimmed={googleSync.status!=='ACTIVE'}>
                    <Header as="h3">Synched Calendars</Header>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Last Synced</Table.HeaderCell>
                                <Table.HeaderCell>Syncing since</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.props.googleCalendarSyncs.map(calendar => <Table.Row key={calendar.uuid}>
                                    <Table.Cell>
                                    {calendar.is_linked?<Label ribbon color="teal"><Image src={logo} /></Label>:calendar.calendar_summary}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {syncStatus(calendar)}
                                    </Table.Cell>
                                    <Table.Cell>
                                    {calendar.creation_date?moment(calendar.creation_date).format('lll'):'n/a'}
                                    </Table.Cell>
                                    <Table.Cell>
                                    <Checkbox disabled={calendar.is_linked} toggle checked={calendar.status.includes('IS_VISIBLE')} onChange={() => this.props.onToggleVisibilityOfGoogleCalendar(calendar)}/>
                                    </Table.Cell>
                                </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                </Dimmer.Dimmable>
            }
                 {
                     googleSync.status==='SUSPENDED'?<Button.Group>
                     <Button color='green' onClick={() => window.open(`${SYNC_BASE_URL}/google/oauth/confirm?at=${this.props.at}&scope=${this.props.scope}`,'google_oauth_consent','width=600,height=800')} content='Reactivate' icon='play' />
                     <Button color='red' onClick={() => this.terminateGSync()} icon='trash' content='Terminate' />
                     </Button.Group>
                     :<Button color='yellow' onClick={() => this.suspendGSync()} size="mini" icon='pause' content='Suspend' />
                    }         
                    </React.Fragment>
        }
           {!googleSync&&  <Message icon>
    <Icon name='sync' size='huge' color='green'/>
    <Message.Content>
      <Message.Header>Activate Google Syncing</Message.Header><br />
      Once activated OfficeMojo will
      <Message.List items={['sync Google Calendar Events associated with OfficeMojo',
      'read, display and block availability for other Google Calendar Events'
      ,'sync Google Contacts with OfficeMojo Clients']} /><br />
      <Button color='green' onClick={() => this.props.available?window.open(`${SYNC_BASE_URL}/google/oauth/confirm?at=${this.props.at}&scope=${this.props.scope}`,'google_oauth_consent','width=600,height=800'):alert('You can only sync to one cloud account at a time!')} content='Activate'/>
    </Message.Content>
  </Message>}
        </Tab.Pane>
    )
}


}

function syncStatus(calendar) {
    if(calendar.status.includes('DOES_NOT_SYNC')) {
        return 'sync disabled';
    }
    if(calendar.last_sync_date){
       return moment(calendar.last_sync_date).fromNow();
    }
    else {
        return <span>initial sync in progress <Icon loading name='circle notch' /></span>
    }
}