import React from 'react'
import { DateTimePicker } from 'react-widgets'

export default class ClickableDateTimePicker extends DateTimePicker {
    constructor(props) {
        super(props);
        this.state = this.getInitialState(props);
    }

  getInitialState(props) {
    return { open: (this.props.open ? this.props.open : false), view: this.props.initialView }
  }

  render() {
    return (
      <DateTimePicker
        {...this.props}
        onChange={(date) => {
          this.props.onChange(date);
          if(this.props.initialView) {
            this.setState({...this.state, 
              open: false,
              view: this.props.initialView})
          }
        }}
        onClick={this._handleInputClick.bind(this, this.props.onClick)}
        onToggle={this._handleToggle.bind(this, this.props.onToggle)}
        open={this.state.open}
        view={this.state.view}
        onViewChange={(view) => this.setState({...this.state,view})}
      />
    )
  }

  _handleInputClick(callback, event) {
      
    if (event.target.tagName === 'INPUT') {
      this._open(true)
    }
    return callback && callback(event)
  }

  _handleToggle(callback, view) {
    this._open(!this.state.open, view)
    return callback && callback()
  }

  _open(shouldOpen, view=null) {
    if (!view) {
      view = (this.props.time && !this.props.date) ? 'time' : 'date'
    }
    const toggledState = shouldOpen ? view : false
    this.setState({open: toggledState})
  }
}