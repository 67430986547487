import React, { Component } from 'react';
import { Dimmer, Loader, Button, Form, Grid, Header, Image, Message, Segment, Popup, Icon } from 'semantic-ui-react'
import './Signup.css';
import logo from 'assets/logo.png'
import { Footer } from 'components/Footer/Footer';
import _, { debounce } from 'lodash';
import { scorePassword } from 'utils/passwordUtils';
//import ReCAPTCHA from "react-google-recaptcha";

export class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company_name: {
        value: '',
        valid: false,
        initial: true,
        loading: false
      },
      first_name: {
        value: '',
        valid: false,
        initial: true,
        loading: false
      },
      last_name: {
        value: '',
        valid: false,
        initial: true,
        loading: false
      },
      user_name: {
        value: '',
        valid: false,
        initial: true,
        loading: false
      },
      email: {
        value: '',
        valid: false,
        initial: true,
        loading: false
      },
      password: {
        value: '',
        valid: false,
        initial: true,
        loading: false
      },
      verify_password: {
        value: '',
        valid: false,
        initial: true,
        loading: false
      }
    }
  }

  _validateInput({name, value}) {
    switch(name){
      case 'company_name':
      this.props.validateScope(_.snakeCase(value))
        return value !== '';
      case 'email':
        return value.search(/.+?@.+?\..+?/) !== -1;
      case 'user_name':
        return value !== '';
      case 'first_name':
        return true;
      case 'last_name':
        return value !== '';
      case 'password':
        return scorePassword(value) >= 64;
      case 'verify_password':
        return value === this.state.password.value;
      default: 
        return value !== '';
    }
  }

  _handleChangeFinish = debounce(({name, value}) => {
    this.setState({
      ...this.state,
      [name]: {
        ...this[name],
        value: value,
        loading: false,
        initial: false,
        valid: this._validateInput({name, value})
      }
    })
  }, 300)

  _handleChange(e) {
    const { name, value } = e.target;
    
    this.setState({
      ...this.state,
      [name]: {
//        ...this[name],
        value,
        loading: true
        
      }
    })
    this._handleChangeFinish({name, value});
  }

  labelForInput(fieldName, validationError, error, errorHelp) {
    let style = {fontWeight:'bold'};
    if(error) {
      style.color = '#d55';
    }
    return <label style={style}>{fieldName+(error?" "+validationError:'')} {error&&errorHelp}</label>;
  }

  _handleSubmit(e) {
    const {company_name, user_name, first_name, last_name, email, password} = this.state
    this.props.onSetup(company_name.value, user_name.value, first_name.value, last_name.value, email.value, password.value, this.props.scope)
  }

  render() {
    const { loading, scope, signup_completed } = this.props;
    const { company_name, user_name, email, password, verify_password, first_name, last_name } = this.state;
    return (
      <div className='login-form'>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
          <Grid
            style={{ height: '100%' }}
            verticalAlign='middle'
            centered={true}
          >
            <Grid.Column style={{ maxWidth: 450 }}>

              <Header as='h1' color='grey' textAlign='center'>
                <Image src={logo} style={{width:'100px'}}/>
              </Header>
            {!signup_completed&&<Form size='large'>
                <Segment >
                {/*<Message
                      error
                      style={{display: !(company_name.valid||company_name.initial) 
                        || !(last_name.valid||last_name.initial)  
                        || !(user_name.valid||user_name.initial) 
                        || !(email.valid||email.initial) 
                        || !(password.valid||password.initial)
                        || !(verify_password.valid||verify_password.initial)? 'block' : 'none'}}
                    >
                      <div className="header">Oops</div>             
                      <ul>
                        {!(company_name.valid||company_name.initial) && <li>A Company Name is required</li>}
                        {!(last_name.valid||last_name.initial) && <li>A Last Name is required</li>}
                        {!(email.valid||email.initial) && <li>A valid Email is required</li>}
                        {!(user_name.valid||user_name.initial) && <li>A Username is required</li>}
                        {!(password.valid||password.initial) && <li>A Password must be minimum 8 charcters.</li>}
                        {!(verify_password.valid||verify_password.initial) && <li>Password and Verify Password must match</li>}
                      </ul>
                      </Message>*/}
                  <Form.Input
                    label={this.labelForInput("Company Name*", " is required", !(company_name.valid||company_name.initial))}
                    fluid
                    error={!(company_name.valid||company_name.initial)}
                    loading={company_name.loading}
                    icon={company_name.initial || company_name.value === '' || !company_name.valid ? 'building' : 'check'}
                    iconPosition='left'
                    placeholder='Company Name'
                    name="company_name"
                    value={company_name.value}
                    onChange={(e) => this._handleChange(e)}
                  />
                  {company_name.valid&&<div>
                    
                    <Message>
                    <Header as="h5">Your custom domain</Header>
                    <code>https://{this.props.scope}.mojo.accountants</code>
                  </Message></div>}
                  <Form.Group widths='equal'>
                  <Form.Input
                    label="First Name"
                    fluid
                    loading={first_name.loading}
                    icon={first_name.initial || first_name.value === '' || first_name.valid ? 'address card' : 'check'}
                    iconPosition='left'
                    placeholder='Your first name'
                    name="first_name"
                    value={first_name.value}
                    onChange={(e) => this._handleChange(e)}
                  />
                  <Form.Input
                    label={this.labelForInput("Last Name*", " is required", !(last_name.valid||last_name.initial))}
                    fluid
                    error={!(last_name.valid||last_name.initial)}
                    loading={last_name.loading}
                    icon={last_name.initial || last_name.value === '' || !last_name.valid ? 'address card' : 'check'}
                    iconPosition='left'
                    placeholder='Your last name'
                    name="last_name"
                    value={last_name.value}
                    onChange={(e) => this._handleChange(e)}
                  /></Form.Group>
                  <Form.Input
                    label={this.labelForInput("Email*", " needs to be valid email address", !(email.valid||email.initial))}
                    fluid
                    error={!(email.valid||email.initial)}
                    loading={email.loading}
                    icon={email.initial || email.value === '' || !email.valid ? 'at' : 'check'}
                    iconPosition='left'
                    placeholder='Email'
                    type='text'
                    name="email"
                    value={email.value}
                    onChange={(e) => this._handleChange(e)}
                  />
                  <Form.Input
                    label={this.labelForInput("User Name*", " is required", !(user_name.valid||user_name.initial))}
                    fluid
                    error={!(user_name.valid||user_name.initial)}
                    loading={user_name.loading}
                    icon={user_name.initial || user_name.value === '' || !user_name.valid ? 'user' : 'check'}
                    iconPosition='left'
                    placeholder='User Name'
                    type='text'
                    name="user_name"
                    value={user_name.value}
                    onChange={(e) => this._handleChange(e)}
                  />
                  <Form.Input
                    label={this.labelForInput("Password*", " - insufficient complexity", !(password.valid||password.initial), <Popup trigger={<Icon name="help circle" />} content='A password should consist of characters, numbers and special characters (!@$%^&amp;*()' />)}
                    fluid
                    error={!(password.valid||password.initial)}
                    loading={password.loading}
                    icon={password.initial || password.value === '' || !password.valid ? 'lock' : 'check'}
                    iconPosition='left'
                    placeholder='Password'
                    name="password"
                    type='password'
                    value={password.value}
                    onChange={(e) => this._handleChange(e)}
                  />
                  <Form.Input
                    label={this.labelForInput("Verify Password*", " does not match Password", !(verify_password.valid||verify_password.initial))}
                    fluid
                    error={!(verify_password.valid||verify_password.initial)}
                    loading={verify_password.loading}
                    icon={verify_password.initial || verify_password.value === '' || !verify_password.valid ? 'lock' : 'check'}
                    iconPosition='left'
                    placeholder='Verify Password'
                    name="verify_password"
                    type='password'
                    value={verify_password.value}
                    onChange={(e) => this._handleChange(e)}
                  />
                 {/*  <div style={{margin: '0 auto',marginBottom:'10px',width:'75%'}}>
                  <ReCAPTCHA
                    sitekey="6Le94GoUAAAAAPLtYWi6IYz7zM8H0ioA5IACCSKQ"
                    onChange={(arg) => console.log('captcha', arg)}
                  /></div> */}

                <Button disabled={!(company_name.valid && user_name.valid && password.valid && verify_password.valid)} color='green' fluid size='large' onClick={(e) => this._handleSubmit(e)}>Sign Up</Button>
                  
                  
                </Segment>
                {/*<div style={{marginBottom:'10px', textAlign: 'center'}}><span onClick={() => alert('Currently not supported')} style={{color:'blue',textDecoration:'underline',cursor:'pointer'}}>Forgot Password</span> | <span onClick={() => this.props.history.push('/')} style={{color:'blue',textDecoration:'underline',cursor:'pointer'}}>Login</span></div> */}
              </Form>}
              {signup_completed&&<Message
                icon='inbox'
                header='Success'
                content={<div><div>Thank you for signing up for Athena. We look forward to growing your business together! Please check your inbox to confirm your email address and access your custom Athena account.</div>
                  <a href={`https://${scope}.mojo.accountants`}>Click here to Login</a>
                </div>}
              />
              }
              <Footer />
            </Grid.Column>
          </Grid>
        
        </div>
    )
  }
}
