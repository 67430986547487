import _ from 'lodash'

const clientHasSearchString = (client, searchString = '') => {
    let search = searchString.toLowerCase()
        return search.length === 0 
        || (client.given_name && client.given_name.toLowerCase().indexOf(search) > -1)
        || (client.family_name && client.family_name.toLowerCase().indexOf(search) > -1)
        || (client.email && client.email.toLowerCase().indexOf(search) > -1)
        || (client.company_name && client.company_name.toLowerCase().indexOf(search) > -1)
    }

const clientHasAnyTag = (client, tags) => {
    return (tags.length === 0 || _.intersection(client.tags, tags).length > 0)
}    

const clientsWithIds = (ids, clients) => {
    return clients.filter((client) => ids.indexOf(client.uuid) !== -1 )
}

const completeName = client  => {
    return _.join([client.given_name,client.family_name], ' ')
}

const concatedClientNames = (ids, clients) => {
    let names = clientsWithIds(ids, clients).map(client => completeName(client));
    return _.join(names, ', ');
} 


export {clientHasSearchString, clientHasAnyTag, clientsWithIds, completeName, concatedClientNames}


