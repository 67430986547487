import React from 'react'
import { Card, Button } from 'semantic-ui-react'
import './TourCard.css'

export const TourCard = ({
  continuous,
  index,
  isLastStep,
  step,
  backProps,
  closeProps,
  primaryProps,
  skipProps,
  tooltipProps
}) => (
    <div {...tooltipProps}>
      <Card className="card-joyride">
        {
          step.title &&
          <Card.Content>
            <Card.Header>{step.title}</Card.Header>
          </Card.Content>
        }
        <Card.Content>
          <Card.Description>{step.content}</Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Button.Group fluid>
            {index > 0 && (
              <Button {...backProps} labelPosition='left' icon='left chevron' content='Back' />
            )}
            {continuous && (
              <Button {...primaryProps} labelPosition='right' icon='right chevron' content='Next' color="green" />
            )}
            {!continuous && (
              <Button {...primaryProps} labelPosition='right' icon='right chevron' content='Finish' color="green" />
            )}
          </Button.Group>
        </Card.Content>
      </Card>
    </div>
  )