import {Observable} from 'rxjs'
import {LOGIN, loginFullfilled, setLoginErrorMessage, handleError, logout, PING, pingFullfilled} from 'actions/SessionActions'
import { requestForEndpointAndPayload } from 'App';
import { CHANGE_PROFESSIONAL, changeProfessionalFullfilled, LOGIN_WITH_TOKEN } from '../actions/SessionActions';


export function loginEpic(action$, store) {
    return action$.ofType(LOGIN)
    .switchMap(({payload}) => {
        return Observable.ajax(requestForEndpointAndPayload('signin', payload))
        .map(result => {
            return loginFullfilled(result.response);
        }).catch((e) => {
            console.log('loginEpic.e', e)
            return Observable.of(setLoginErrorMessage('Could not find user with username and/or password!'))
        })
    });
}

export function loginWithTokenEpic(action$, store) {
    return action$.ofType(LOGIN_WITH_TOKEN)
    .switchMap(({payload}) => {
        return Observable.ajax(requestForEndpointAndPayload('signin', payload))
        .map(result => {
            return loginFullfilled(result.response);
        }).catch((e) => {
            console.log('loginEpic.e', e)
            return Observable.of(setLoginErrorMessage('Could not find user with username and/or password!'))
        })
    });
}

export function changeProfessionalEpic(action$, store) {
    return action$.ofType(CHANGE_PROFESSIONAL)
   .map((payload) => {
        setTimeout(()=>document.dispatchEvent(new Event('professionalChanged')),10);
            return changeProfessionalFullfilled();
            //return Observable.of({})
        }).catch((e) => {
            console.log('changeProfessionalEpic.e', e)
            return Observable.of({})
        });
        
}

export function pingEpic(action$) {
    return action$.ofType(PING)
    .switchMap(() => {
        return Observable.ajax(requestForEndpointAndPayload('ping', {do:1234}))
    .map(result => {
        return pingFullfilled(result.response);
    }).catch((e) => {
        console.log("error from server", e)
        return Observable.of(handleAjaxError(e));
    })
});
}

export function handleAjaxError(ajaxError) {
    console.log('ajaxError', ajaxError)
    if(ajaxError.response && ajaxError.response.error === 'expired token') {
        window.alert('Your session expired')
        return logout();
    }
    let errorMessage = '';
    if(ajaxError.response&&ajaxError.response.errors&&ajaxError.response.errors.reduce) {
        console.log(ajaxError.response)
        errorMessage = ajaxError.response.errors.reduce((value, currentValue) => `${value}, ${currentValue}`)
    } else {
        errorMessage = ajaxError.response?ajaxError.response.error:'Error contacting server'
    }
    return handleError(errorMessage)
}

//https://github.com/Reactive-Extensions/RxJS-DOM/blob/master/doc/operators/ajax.md
