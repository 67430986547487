import React from 'react'
import { Header, Table, Label, Icon, Tab, Button, Image, Dimmer, Segment, Message, Checkbox } from 'semantic-ui-react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import moment from 'moment';
import logo from 'assets/logoW.png'
import { HelpLink } from 'widgets/HelpLink/HelpLink';


const SYNC_BASE_URL = `https://sync.${window.location.host.includes('.dev.')?'dev.':''}mojo.accountants/cgi-bin/WebObjects/sync.woa`;
//const SYNC_BASE_URL = `http://127.0.0.1:4444/cgi-bin/WebObjects/sync.woa`;


export class ZoomSettingTab extends React.Component{
    
    constructor(props) {
        super(props);
        this.messageHandler = (event) => {
            if(event.data&&event.data.startsWith&&event.data.startsWith('{')) {
                try{
                    const data = JSON.parse(event.data)
                    if(data.channel!=="ZOOM_OAUTH_RESPONSE") {
                        return;
                    }
                    if(data.result==='failed') {
                        this.props.handleError(data.details)
                    }
                    this.props.refreshSyncSettings(event)
                } catch(e){
                    console.log(e)
                    return;
                }                
            }
            
        };
        this.state = {};
    }
    
    terminateGSync() {
        if(window.confirm('Do you really want to terminate Zoom Integration?')){
            fetch(`${SYNC_BASE_URL}/zoom/oauth/terminate?at=${this.props.at}&scope=${this.props.scope}`)
                .then(
                    (response) => {
                        if (response.status !== 200) {
                            this.props.handleError("Terminating failed. Please try again later or contact customer support.")
                            return;
                        }
                        this.props.refreshSyncSettings()
                    }).catch(err => 
                        this.props.handleError("Terminating failed. Please try again later or contact customer support."));

        }
    }

    suspendGSync() {
        if(window.confirm('Do you really want to suspend Zoom Integration?')){
            fetch(`${SYNC_BASE_URL}/zoom/oauth/suspend?at=${this.props.at}&scope=${this.props.scope}`)
                .then(
                    (response) => {
                        if (response.status !== 200) {
                            this.props.handleError("Suspending failed. Please try again later or contact customer support.")
                            return;
                        }
                        this.props.refreshSyncSettings()
                    }).catch(err => {
                        console.log(err)
                        return this.props.handleError("Suspending failed. Please try again later or contact customer support.")}
                );
        }
    }

    componentDidMount() {
        this.setState({...this.state, updateInterval:window.setInterval(() => this.props.refreshSyncSettings(), 30*1000)})
        window.addEventListener("message", this.messageHandler);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.messageHandler);
        window.clearInterval(this.state.updateInterval);
    }

   

    render() {
        const zoomSetting = this.props.zoomSettings[0];
        
        return (
        <Tab.Pane>

                <Header as='h3'>
        {zoomSetting&&<Icon loading={zoomSetting.status==='ACTIVE'} size='big' name={zoomSetting.status==='ACTIVE'?'sync alternate':'pause'} color={zoomSetting.status==='ACTIVE'?'green':'yellow'}/>} Zoom Integration 
                <HelpLink topic="zoomSync" /></Header>
                {zoomSetting&&<React.Fragment><span>{zoomSetting.status==='ACTIVE'?<span>
      syncing since {moment(zoomSetting.creation_date).format('lll')}<br />
    </span>:<span>Zoom Integration has been suspended</span>}</span>
        
                 {
                     zoomSetting.status==='SUSPENDED'?<Button.Group>
                     <Button color='green' onClick={() => window.open(`${SYNC_BASE_URL}/zoom/oauth/confirm?at=${this.props.at}&scope=${this.props.scope}`,'zoom_consent','width=600,height=800')} content='Reactivate' icon='play' />
                     <Button color='red' onClick={() => this.terminateGSync()} icon='trash' content='Terminate' />
                     </Button.Group>
                     :<Button color='yellow' onClick={() => this.suspendGSync()} size="mini" icon='pause' content='Suspend' />
                    }         
                    </React.Fragment>
        }
           {!zoomSetting&&  <Message icon>
    <Icon name='sync' size='huge' color='green'/>
    <Message.Content>
      <Message.Header>Activate Zoom Integration</Message.Header><br />
      Once activated OfficeMojo will
      <Message.List items={['Create Zoom Meetings for OfficeMojo Appointments from within OfficeMojo.']} /><br />
      <Button color='green' onClick={() => this.props.available?window.open(`${SYNC_BASE_URL}/zoom/oauth/confirm?at=${this.props.at}&scope=${this.props.scope}`,'zoom_oauth_consent','width=600,height=800'):alert('You can only sync to one cloud account at a time!')} content='Activate'/>
    </Message.Content>
  </Message>}
        </Tab.Pane>
    )
}


}

function syncStatus(calendar) {
    if(calendar.status.includes('DOES_NOT_SYNC')) {
        return 'sync disabled';
    }
    if(calendar.last_sync_date){
       return moment(calendar.last_sync_date).fromNow();
    }
    else {
        return <span>initial sync in progress <Icon loading name='circle notch' /></span>
    }
}