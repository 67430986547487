import { SAVE_APPOINTMENT, SET_COMMUNICATION_PREFERENCES, INSERT_NEW_APPOINTMENT, DELETE_APPOINTMENT, SAVE_APPOINTMENT_FULLFILLED, FETCH_APPOINTMENTS, FETCH_APPOINTMENTS_FULLFILLED, DELETE_APPOINTMENT_FULLFILLED, SELECT_APPOINTMENT, SELECT_APPOINTMENT_FULLFILLED, CLEAR_SELECTED_APPOINTMENT, FETCH_SELECT_APPOINTMENT, FETCH_APPOINTMENT_REQUESTS, FETCH_APPOINTMENT_REQUESTS_FULLFILLED, SELECT_APPOINTMENT_REQUEST, CLEAR_SELECTED_APPOINTMENT_REQUEST, APPEND_APPOINTMENT_TO_APPT_REQUEST, APPEND_APPOINTMENT_TO_APPT_REQUEST_FULLFILLED, SAVE_APPOINTMENT_REQUEST_FULLFILLED, SAVE_APPOINTMENT_REQUEST, REJECT_APPOINTMENT_REQUEST, REJECT_APPOINTMENT_REQUEST_FULLFILLED, FETCH_GOOGLE_EVENTS_FULLFILLED, FETCH_GOOGLE_EVENTS } from 'actions/AppointmentActions';
import { FETCH_MICROSOFT_EVENTS_FULLFILLED, FETCH_MICROSOFT_EVENTS, FETCH_HOLIDAY_EVENTS, FETCH_HOLIDAY_EVENTS_FULLFILLED, FETCH_BIRTHDAYS, FETCH_BIRTHDAYS_FULLFILLED } from '../actions/AppointmentActions';


const INITIAL_STATE = { appointments:[], appointmentRequests:[], googleEvents:[], microsoftEvents:[], communicationPreferences:[],loading:false,holidayEvents:[],birthdays:[]};

const CalendarReducer = (state = INITIAL_STATE, action) => {
    let {payload, type} = action
    let appointments = state.appointments;
    switch(type) {
        case FETCH_APPOINTMENTS:
        return { ...state,
            date:payload.date,
            view:payload.view,
           /*  loading: true */ }
        case FETCH_APPOINTMENT_REQUESTS:
            if(payload.selectedAppointmentRequest) {
                return {...state, loading: true};
            }
            return state;
        case FETCH_APPOINTMENT_REQUESTS_FULLFILLED:
            return {...state, 
                selectedAppointmentRequest:payload.dateRequests.filter(request => request.uuid === payload.selectedAppointmentRequest)[0],
                appointmentRequests:payload.dateRequests,
                loading: false};
        case FETCH_GOOGLE_EVENTS:
            return state;
        case FETCH_GOOGLE_EVENTS_FULLFILLED:
            return {...state, 
                googleEvents:payload.googleEvents,
                };
        case FETCH_MICROSOFT_EVENTS:
            return state;
        case FETCH_MICROSOFT_EVENTS_FULLFILLED:
            return {...state, 
                microsoftEvents:payload.microsoftEvents,
                };
        case FETCH_HOLIDAY_EVENTS:
            return state;
        case FETCH_HOLIDAY_EVENTS_FULLFILLED:
            return {...state, 
                holidayEvents:payload.holidayEvents,
                };
        case FETCH_BIRTHDAYS:
            return state;
        case FETCH_BIRTHDAYS_FULLFILLED:
            return {...state, 
                birthdays:payload.birthdays,
                };
        case SET_COMMUNICATION_PREFERENCES:
            return { ...state,
                communicationPreferences:payload}
        case FETCH_APPOINTMENTS_FULLFILLED:
            return { ...state, 
                appointments: payload,
                 loading: false  }
        case SAVE_APPOINTMENT:
        case SAVE_APPOINTMENT_REQUEST:
        return { ...state,
            loading: true }      
        case SAVE_APPOINTMENT_FULLFILLED:
            return { ...state, appointments: filterOutAppointment(appointments,payload)
                .concat(payload),
                selectedAppointment: null,
                loading: false }
        case FETCH_SELECT_APPOINTMENT: 
            return {
                ...state,
                loading: true
            }
        case SELECT_APPOINTMENT_REQUEST:
            return { ...state,
                selectedAppointmentRequest:payload }
        case SAVE_APPOINTMENT_REQUEST_FULLFILLED:
            return { ...state,
                selectedAppointmentRequest:null }
        case CLEAR_SELECTED_APPOINTMENT_REQUEST:
            return { ...state, 
                selectedAppointmentRequest: null
            } 
        case APPEND_APPOINTMENT_TO_APPT_REQUEST: 
            return {
                ...state,
                loading:true
            }
        case APPEND_APPOINTMENT_TO_APPT_REQUEST_FULLFILLED: 
            return {
                ...state,
                selectedAppointmentRequest:payload,
                loading:false
            }
        case SELECT_APPOINTMENT:
            return { ...state,
                loading: true }
        case SELECT_APPOINTMENT_FULLFILLED:
            return { ...state, 
                selectedAppointment: payload,
                loading: false }
        case CLEAR_SELECTED_APPOINTMENT:
            return { ...state, 
                selectedAppointment: null,
                loading: false }           
        case DELETE_APPOINTMENT:
            return { ...state,
                loading: true}
        case DELETE_APPOINTMENT_FULLFILLED:
            return { ...state, 
                appointments: filterOutAppointment(appointments,{uuid:payload}),
                selectedAppointment: null,
                loading: false}
        case REJECT_APPOINTMENT_REQUEST:
                return { ...state,
                    loading: true}
        case REJECT_APPOINTMENT_REQUEST_FULLFILLED:
                return { ...state, 
                    appointmentRequests: filterOutAppointment(state.appointmentRequests,payload),
                    selectedAppointmentRequest: null,
                    loading: false}
        case INSERT_NEW_APPOINTMENT:
            return { appointments: filterOutAppointment(appointments,payload)
                                .concat(payload),
                                loading: false}
        default: 
            return state;
    }
}

export default CalendarReducer;

const filterOutAppointment = (appointments, appointment) => 
    appointments.filter(currentAppointment => appointment!=null&&currentAppointment.uuid !== appointment.uuid);