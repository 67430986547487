import { connect } from 'react-redux';
import {Clients} from 'components/Clients/Clients';
import { deleteClient, saveClient, fetchClients, fetchSelectedClient, clearSelectedClient, addClient, saveClientsAppointment } from 'actions/ClientActions'
import { fetchTags } from 'actions/TagActions';
import { clearSelectedAppointment, deleteAppointment, selectAppointment } from 'actions/AppointmentActions';
import { DATE_REMINDER } from 'utils/communicationSettingsUtils';
import { fetchSettings } from '../../actions/SettingsActions';



const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleSaveChanges: (client) => {
            dispatch(saveClient(client))
        },
        handleDeleteClient: (client) => {
            dispatch(deleteClient(client))            
        },
        handleFetchClients: (professionalId, page, length, searchTerm, tags) => dispatch(fetchClients(professionalId, page, length, searchTerm, tags)),
        handleFetchTags: (professionalId) => dispatch(fetchTags(professionalId)),
        handleSelectClient: (client) => dispatch(fetchSelectedClient(client.uuid)),
        handleClearSelectedClient: () => dispatch(clearSelectedClient()),
        handleAddClient: (client) => dispatch(addClient(client)),
        handleFetchSettings: () => dispatch(fetchSettings()),
        handleShowDate: (clientId,dateproxy) => {
            dispatch(selectAppointment(dateproxy))
           // dispatch(push('/calendar'))
        },
        onFetchTags: (professional, page, length ) => {
            dispatch(fetchTags(professional, page, length))
        },
        onSaveAppointment: (appt, page, length, searchTerm, tags) => dispatch(saveClientsAppointment(appt, page, length, searchTerm, tags)),
        handleDeleteAppointment: (appt) => {
            dispatch(deleteAppointment(appt))
        },
        onClearSelectedAppointment: (selectedAppointment) => dispatch(clearSelectedAppointment())
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        clients_pagination_info: state.clients.clients_pagination_info,
        clients: state.clients.clients,
        availableTags: state.tags.tags,
        professional: state.session.professional,
        professional_object: state.settings.professionals[0],
        office: state.settings.offices[0],
        loading: state.clients.loading || state.tags.loading || state.appointments.loading,
        selectedClient: state.clients.selectedClient,
        selectedAppointment:state.appointments.selectedAppointment,
        communicationMessageTemplates:state.metaData.communicationMessageTemplates,
        reminderOffsets:state.metaData.reminderOffsets,
        occasions:state.metaData.occasions,
        communicationTypes:state.metaData.communicationTypes,
        communicationPreferences:state.appointments.communicationPreferences.filter(pref => pref.occasion === DATE_REMINDER),
        dateLocations:state.metaData.dateLocations,
        zoomSetting:state.integrationSettings.zoomSetting,
        microsoftSyncSetting:state.integrationSettings.microsoftSyncSetting,
        googleSyncSetting:state.integrationSettings.googleSyncSetting
    }
}

const ClientsContainer = connect(mapStateToProps, mapDispatchToProps)(Clients);

export {ClientsContainer}