import React from 'react';
import { Label, Checkbox, Dropdown, Button, Form } from 'semantic-ui-react';
import { composePreviewURL } from 'utils/previewUtils';



export const CommunicationSetup = (props) => {
    let reminder = props.reminder;

    let activeCheckbox = <Label icon="truck" fitted="true" color="blue" inverted="true"/>;
    
    if (reminder.status!=='Processed') {
        activeCheckbox = <Checkbox toggle 
    disabled={props.disabled(reminder)}
    checked={reminder.status!=='Inactive'}
    onChange={(event, {checked}) => props.onChange?props.onChange({...reminder,status:checked?'Active':'Inactive'}):console.log(event, checked)}
    />}

    let offsetDropdown = null;
    if(props.reminderOffsets) {
        offsetDropdown = ( 
            <React.Fragment>
         {props.standalone&&<label>Lead Time</label>}
        <Dropdown 
        disabled={reminder.status==='Processed'}
        placeholder='Lead Time' 
        value={reminder.offset_type} 
        fluid 
        selection 
        options={props.reminderOffsets.map(offset => {return {text:offset.beautified_name,value:offset.name}})}
        onChange={(event, {value}) => props.onChange?props.onChange({...reminder,offset_type:value}):console.log(value)}/>
        </React.Fragment>)
        }
    const communicationDropdown = (<Dropdown 
        disabled={reminder.status==='Processed'}
        placeholder='Communication Type' 
        value={reminder.communication_type}  
        fluid 
        selection 
        options={props.communicationTypes.map(type => { return {text:type.beautified_name,value:type.name}})}
        onChange={(event, {value}) => props.onChange?props.onChange({...reminder,communication_type:value}):console.log(value)}/>);
    
    const templateDropdown = (<Dropdown 
        disabled={reminder.status==='Processed'}
        placeholder='Message Template' 
        value={reminder.communication_message_template} 
        fluid 
        selection 
        options={props.communicationMessageTemplates.map(template => {return {text:template.name,value:template.uuid}})} 
        onChange={(event, {value}) => props.onChange?props.onChange({...reminder,communication_message_template:value}):console.log(value)}
        />);

    let previewButton = null;
    if (props.showPreview) {
        let previewURL = props.previewURL;
        if(!previewURL) {
            previewURL = composePreviewURL(props.receiver, props.sender, props.date, reminder.communication_message_template, reminder.communication_type) 
        }

        previewButton = (<Button size="mini" onClick={() => window.open(previewURL)} icon="eye" circular/>);    
    }
    if(props.standalone) {
        return (
        <Form>
            <Form.Group>
                <Form.Field width={2}>
                    <label>Active</label>
                    {activeCheckbox}
                </Form.Field>

                <Form.Field width={4}>
                <label>Communication Type</label>
                    {communicationDropdown}
                </Form.Field>
                <Form.Field width={5}>
                <label>Message Template</label>
                    {templateDropdown}
                </Form.Field>
                <Form.Field width={3}>
                
                {offsetDropdown}
            </Form.Field>
                <Form.Field width={2}>
                <label>Preview</label>
                    {previewButton}
                </Form.Field>
            </Form.Group>
        </Form>

        )

    }
    return (
        <tr>
            <td>
                {activeCheckbox}
            </td>
            <td>
                {offsetDropdown}
            </td>
            <td>
                {communicationDropdown}
            </td>
            <td>
                {templateDropdown}
            </td>
            <td style={{width:'20px'}}>
                {previewButton}
            </td>
        </tr>
    )
}