
export const OPENING_TIME_OPTIONS = possibleHoursOfDay().map(hour => {return {text:hourToTimeString(hour),value:hourToMilliseconds(hour)}})
export const APPT_LENGTH_OPTIONS = [
    //{text:'15min', value: 15*60*1000},
    {text:'30min', value: 30*60*1000},
   // {text:'45min', value: 45*60*1000},
    {text:'1h', value: 60*60*1000},
   // {text:'1h:15min', value: 75*60*1000},
    {text:'1h:30min', value: 90*60*1000},
   // {text:'1h:45min', value: 105*60*1000},
    {text:'2h', value: 120*60*1000},
    {text:'3h', value: 180*60*1000},
    {text:'4h', value: 240*60*1000},
    {text:'5h', value: 300*60*1000},
] 

function possibleHoursOfDay() {
    let hours = [];
    let hour = 0;
    while(hour<24) {
        hours.push(hour);
        hour = hour+0.5;
    }
    return hours;
}

function hourToMilliseconds(hour) {
    return hour*60*60*1000;
}

function hourToTimeString(hour) {
    let hourValue = Math.floor(hour);
    let minuteValue = 60*(hour-hourValue);
    minuteValue = minuteValue<10?'0'+minuteValue:minuteValue
    let dayPart = hourValue>=12?'PM':'AM';
    hourValue = hourValue>12?hourValue-12:hourValue;
    return `${hourValue}:${minuteValue} ${dayPart}`
}

export const WEEKDAYS = [
    {text:'Sunday',value:'0'},
    {text:'Monday',value:'1'},
    {text:'Tuesday',value:'2'},
    {text:'Wednesday',value:'3'},
    {text:'Thursday',value:'4'},
    {text:'Friday',value:'5'},
    {text:'Saturday',value:'6'}
]