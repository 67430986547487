import {Observable} from 'rxjs'

import { requestForEndpointAndPayload } from 'App';
import { SHOW_MIC, showMICFullfilled, RESET_MIC_TOKEN } from 'actions/MICActions';
import { handleAjaxError } from './sessionEpic';


export function showMICEpic(action$, store) {
    return action$.ofType(SHOW_MIC)
    .switchMap(({payload}) => {
        if(!store.getState().mic.account_token) {
            
            return Observable.ajax(requestForEndpointAndPayload('sso/mic', {
                    professional:store.getState().session.professional
                    }
                )
            ).switchMap(result => {
                return Observable.of({...result.response, showMIC:payload})}
            )
        } else {
            return Observable.of({...store.getState().mic, showMIC:payload});
        }
    
    })
        .map(result => {
            return showMICFullfilled(result);
        }).catch((e) => {
            console.log('getMessageCenterTokenEpic.e',e)
            return Observable.concat([showMICFullfilled({...store.getState().mic, showMIC:false}),handleAjaxError(e)])
        });
}

export function resetMICTokenEpic(action$, store) {
    return action$.ofType(RESET_MIC_TOKEN)
    .switchMap(({payload}) => {

            
            return Observable.ajax(requestForEndpointAndPayload('sso/mic', {
                    professional:store.getState().session.professional
                    }
                )
            ).switchMap(result => {
                return Observable.of({...result.response, showMIC:payload})}
            )
    
    })
        .map(result => {
            return showMICFullfilled(result);
        }).catch((e) => {
            console.log('getMessageCenterTokenEpic.e',e)
            return Observable.concat([showMICFullfilled({...store.getState().mic, showMIC:false}),handleAjaxError(e)])
        });
}