import React, { Component } from 'react'
import { SMS, EMAIL, CALL, POSTCARD } from 'constants/CommunicationTypes';
import { Header, Segment, Icon, Checkbox, Form, Tab, Input, Button, Accordion } from 'semantic-ui-react'
import { TextFormField } from 'widgets/FormWidgets';
import { CommunicationSetup } from 'widgets/CommunicationSetup';
import { settingForOccasion, DATE_NOTIFICATION, BIRTHDAY, templatesForOccasion, DATE_REMINDER, settingsForOccasion, communicationTypesForOccasion } from 'utils/communicationSettingsUtils';
import { createId } from 'utils/uuidUtils';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { PreviewButton } from 'widgets/PreviewButton';
import { sortByOffsetTypes } from 'utils/offsetTypeUtils';

export const communicationSettingsTab = (company, office, professional, communicationMessageTemplates, occasions, reminderOffsets, communicationTypes, onChange, onSave) => {
  let notificationSetting = settingForOccasion(professional, DATE_NOTIFICATION);
  let notificationTemplates = templatesForOccasion(communicationMessageTemplates, DATE_NOTIFICATION);
  let notificationCommunicationTypes = communicationTypesForOccasion(communicationTypes, occasions, DATE_NOTIFICATION);
  let birthdaySetting = settingForOccasion(professional, BIRTHDAY);
  let birthdayTemplates = templatesForOccasion(communicationMessageTemplates, BIRTHDAY);
  let birthdayCommunicationTypes = communicationTypesForOccasion(communicationTypes, occasions, BIRTHDAY);
  let reminderSettings = sortByOffsetTypes(reminderOffsets, settingsForOccasion(professional, DATE_REMINDER));/*.sort((a,b) => {
        let aIndex = a.uuid?a.uuid:a.tuuid;
        let bIndex = b.uuid?b.uuid:b.tuuid;
        return aIndex.localeCompare(bIndex);
    });*/
  let firstReminderSetting = reminderSettings[0];
  let secondReminderSetting = reminderSettings[1];
  let thirdReminderSetting = reminderSettings[2];
  let reminderTemplates = templatesForOccasion(communicationMessageTemplates, DATE_REMINDER);
  let reminderCommunicationTypes = communicationTypesForOccasion(communicationTypes, occasions, DATE_REMINDER);
  if (!birthdaySetting) {
    birthdaySetting = {
      status: 'Inactive',
      occasion: BIRTHDAY,
      communication_message_template: birthdayTemplates[0].uuid,
      communication_type: communicationTypes[0].name,
      tuuid: createId()
    };
  }
  if (!notificationSetting) {
    notificationSetting = {
      status: 'Inactive',
      occasion: DATE_NOTIFICATION,
      communication_message_template: notificationTemplates[0].uuid,
      communication_type: communicationTypes[0].name,
      tuuid: createId()
    };
  }
  if (!firstReminderSetting) {
    firstReminderSetting = {
      status: 'Inactive',
      occasion: DATE_REMINDER,
      offset_type: reminderOffsets[0].name,
      communication_message_template: reminderTemplates[0].uuid,
      communication_type: communicationTypes[0].name,
      tuuid: createId()
    };
  }
  if (!secondReminderSetting) {
    secondReminderSetting = {
      status: 'Inactive',
      occasion: DATE_REMINDER,
      offset_type: reminderOffsets[1].name,
      communication_message_template: reminderTemplates[0].uuid,
      communication_type: communicationTypes[0].name,
      tuuid: createId()
    };
  }
  if (!thirdReminderSetting) {
    thirdReminderSetting = {
      status: 'Inactive',
      occasion: DATE_REMINDER,
      offset_type: reminderOffsets[2].name,
      communication_message_template: reminderTemplates[0].uuid,
      communication_type: communicationTypes[0].name,
      tuuid: createId()
    };
  }
  const replaceSetting = (newPref) => {
    let newSettings = professional.communication_preferences
      .filter(currentPref => newPref.tuuid !== currentPref.tuuid
        || newPref.uuid !== currentPref.uuid)
      .concat(newPref);
    return newSettings;
  }

  return (
    <Tab.Pane>
      <Header as='h3'>Communication Settings</Header>
      <Tab panes={[
        {
          menuItem: { key: 'reminder', icon: 'bell', content: 'Appointment Reminders' },
          render: () => (
            <Tab.Pane>
              <Segment>
                <Form.Field>
                  <label>
                    Confirmation Emails<span style={{ fontWeight: 'normal' }}> - receive an email when a client confirms an appointment.</span>
                  </label>
                  <Checkbox
                    toggle
                    checked={company.email_confirmation_notification}
                    onChange={(event, { checked }) => onChange('company', checked, 'email_confirmation_notification')}
                  />
                </Form.Field>
              </Segment>
              <Segment.Group raised>
                <Segment>
                  Set the default settings for outgoing appointment reminders.
                                </Segment>
                <Segment>
                  <CommunicationSetup
                    standalone={true}
                    sender={professional.uuid}
                    disabled={() => false}
                    showPreview={true}
                    index={0}
                    reminderOffsets={reminderOffsets}
                    communicationTypes={reminderCommunicationTypes}
                    communicationMessageTemplates={reminderTemplates}
                    reminder={firstReminderSetting}
                    onChange={(setting) => onChange('professional', replaceSetting(setting), 'communication_preferences')}
                  />
                </Segment>
                <Segment>
                  <CommunicationSetup
                    standalone={true}
                    sender={professional.uuid}
                    disabled={() => false}
                    showPreview={true}
                    index={1}
                    reminderOffsets={reminderOffsets}
                    communicationTypes={reminderCommunicationTypes}
                    communicationMessageTemplates={reminderTemplates}
                    reminder={secondReminderSetting}
                    onChange={(setting) => onChange('professional', replaceSetting(setting), 'communication_preferences')}
                  />
                </Segment>
                <Segment>
                  <CommunicationSetup
                    standalone={true}
                    sender={professional.uuid}
                    disabled={() => false}
                    showPreview={true}
                    index={2}
                    reminderOffsets={reminderOffsets}
                    communicationTypes={reminderCommunicationTypes}
                    communicationMessageTemplates={reminderTemplates}
                    reminder={thirdReminderSetting}
                    onChange={(setting) => onChange('professional', replaceSetting(setting), 'communication_preferences')}
                  />
                </Segment>
                <Segment>
                  <Button type='submit' color="green" onClick={() => onSave()}>
                    Save
                                    </Button>
                </Segment>
              </Segment.Group>
            </Tab.Pane>
          )
        },
        {
          menuItem: { key: 'notification', icon: 'calendar check', content: 'Appointment Notification' },
          render: () => (
            <Tab.Pane>
              <Segment.Group raised>
                <Segment>
                  Set the default settings for outgoing appointment notifications whenever an appointment is created, or modified.
                                </Segment>
                <Segment>
                  <CommunicationSetup
                    standalone={true}
                    sender={professional.uuid}
                    disabled={() => false}
                    showPreview={true}
                    index={0}
                    communicationTypes={notificationCommunicationTypes}
                    communicationMessageTemplates={notificationTemplates}
                    reminder={notificationSetting}
                    onChange={(setting) => onChange('professional', replaceSetting(setting), 'communication_preferences')}
                  />
                </Segment>
                <Segment>
                  <Button type='submit' color="green" onClick={() => onSave()}>
                    Save
                                    </Button>
                </Segment>
              </Segment.Group>
            </Tab.Pane>
          )
        },
        {
          menuItem: { key: 'birthday', icon: 'gift', content: 'Birthday' },
          render: () => (
            <Tab.Pane>
              <Segment.Group raised>
                <Segment>
                  Set the default settings for your outgoing birthday wishes.
                                </Segment>
                <Segment>
                  <CommunicationSetup
                    standalone={true}
                    sender={professional.uuid}
                    disabled={() => false}
                    showPreview={true}
                    index={0}
                    communicationTypes={birthdayCommunicationTypes}
                    communicationMessageTemplates={birthdayTemplates}
                    reminder={birthdaySetting}
                    onChange={(setting) => {
                      let settings = replaceSetting(setting)
                      onChange('professional', settings, 'communication_preferences')
                    }
                    }
                  />
                </Segment>
                <Segment>
                  <Button type='submit' color="green" onClick={() => onSave()}>
                    Save
                                    </Button>
                </Segment>
              </Segment.Group>
            </Tab.Pane>
          )
        },
        {
          menuItem: { key: 'links', icon: 'linkify', content: 'Links' },
          render: () => (
            <Tab.Pane>
              <Segment.Group raised>
                <Segment>
                  Set links to be displayed in messages.
                                </Segment>
                <Segment>
                  <Form>
                    <Form.Group widths='equal'>
                      <TextFormField
                        placeholder='Review Link'
                        label={<label>Review Link<span style={{ fontWeight: 'normal' }}> – the link where you'd like to send clients to leave a review.</span></label>}
                        valueKey='review_link_url'
                        object={office}
                        update={(value, key) => onChange('office', value, key)}
                        control={Input}
                      />
                    </Form.Group>
                  </Form>
                </Segment>
                <Segment>
                  <Button type='submit' color="green" onClick={() => onSave()}>
                    Save
                                    </Button>
                </Segment>
              </Segment.Group>
            </Tab.Pane>
          )
        },
        {
          menuItem: { key: 'personalizations', icon: 'edit', content: 'Personalization' },
          render: () => (
            <Tab.Pane>
              <TemplatePersonalization
                professional={professional}
                office={office}
                communicationMessageTemplates={communicationMessageTemplates}
                onChange={onChange}
                onSave={onSave}
              />
            </Tab.Pane>
          )
        }
      ]} />
    </Tab.Pane>
  )
}

class TemplatePersonalization extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleOpenClose(index) {
    if (index !== this.state.activeIndex) {
      this.setState({ ...this.state, activeIndex: index })
    } else {
      this.setState({ ...this.state, activeIndex: undefined })
    }
  }

  label(communicationType, template) {
    let labelString;
    switch (communicationType) {
      case SMS:
        labelString = 'Text Messages';
        break
      case EMAIL:
        labelString = 'Email';
        break
      case POSTCARD:
      case CALL:
      default:
    }
    const templatePersonalization = this.personalizationForTemplate(template, communicationType).personalized_note;
    return (
      <label>{labelString} <PreviewButton templatePersonalization={templatePersonalization} sender={this.props.professional.uuid} communicationMessageTemplate={template.uuid} communicationType={communicationType} /></label>
    )
  }

  updateValue(template, communicationType, personalizationString) {
    let personalization = this.personalizationForTemplate(template, communicationType);
    personalization.personalized_note = personalizationString;
    let personalizations = [...this.props.professional.template_personalizations.filter(currentPersonalization => {
      return personalization !== currentPersonalization
        && (!personalization.uuid || personalization.uuid !== currentPersonalization.uuid)
    }), personalization];
    this.props.onChange('professional', personalizations, 'template_personalizations')
  }

  personalizationForTemplate(template, communicationType) {
    let personalization = this.props
      .professional.template_personalizations
      .filter(currentPersonalization => {
        return currentPersonalization.communication_message_template ===
          template.uuid
          && currentPersonalization.communication_type === communicationType
      })[0];
    if (!personalization) {
      personalization = {
        communication_message_template: template.uuid,
        professional: this.props.professional.uuid,
        office: this.props.office.uuid,
        company: this.props.professional.company,
        communication_type: communicationType
      }
    }
    return personalization;
  }

  render() {
    return (
      <React.Fragment>
        Add a personal message to any template. Simply select the template below and add a message.
            <Form>
          <Segment>
            {
              this.props.communicationMessageTemplates.map((template, index) => (<div key={template.uuid}>
                <Accordion fluid styled>
                  <Accordion.Title active={this.state.activeIndex === index} index={index} onClick={() => this.handleOpenClose(index)}>
                    <Icon name='dropdown' />
                    {template.name}
                  </Accordion.Title>
                  <Accordion.Content active={this.state.activeIndex === index} index={index} >
                    <Form.TextArea value={this.personalizationForTemplate(template, SMS).personalized_note} label={this.label(SMS, template)} placeholder={`Add custom text to '${template.name}' in Text Messages`} onChange={(target, { value }) => this.updateValue(template, SMS, value)} />
                    <Form.TextArea value={this.personalizationForTemplate(template, EMAIL).personalized_note} label={this.label(EMAIL, template)} placeholder={`Add custom text to '${template.name}' in Emails`} onChange={(target, { value }) => this.updateValue(template, EMAIL, value)} />
                  </Accordion.Content>
                </Accordion>
              </div>))
            }
          </Segment>
          <Button type='submit' color="green" onClick={() => this.props.onSave()}>Save</Button>
        </Form>
      </React.Fragment>
    )
  }
}