// @flow

import {Observable} from 'rxjs'
import { requestForEndpointAndPayload } from 'App';


import { setCommunicationMessageTemplates, setCommunicationTypes, setOccasions } from 'actions/MetaDataActions';
import { setReminderOffsets } from 'actions/MetaDataActions';
import { handleAjaxError } from './sessionEpic';
import { SAVE_SMART_TAG, saveSmartTagFullfilled, fetchClientsForCategoryFullfilled, FETCH_CLIENTS_FOR_CATEGORY } from '../actions/SmartTagActions';
import { fetchCategoriesFullfilled } from '../actions/SmartTagActions';
import { FETCH_CATEGORIES } from '../actions/SmartTagActions';


export function fetchCategoriesEpic(action$, store) {
  
    return action$.ofType(FETCH_CATEGORIES)
    .switchMap(({payload}) => {
        const {professional} = payload;
        return Observable.ajax(requestForEndpointAndPayload('get',{
            category_ids:  '*',
            communication_message_template_ids:"*",
            communication_type_ids:"*",
            reminder_offset_ids:"*",
            occasion_ids:'*'   
        })
            )
        .switchMap(categoryResponse => {
            store.dispatch(setCommunicationMessageTemplates(categoryResponse.response.communication_message_templates));
            store.dispatch(setReminderOffsets(categoryResponse.response.reminder_offsets));
            store.dispatch(setCommunicationTypes(categoryResponse.response.communication_types));
            store.dispatch(setOccasions(categoryResponse.response.occasions));
        return Observable.ajax(requestForEndpointAndPayload('query',{
            smart_tags:  [
                        {
                            and : [["professional","=", professional]]
                        }
                    ]
        })).switchMap(smartTagResponse =>{
            return Observable.of(categoryResponse.response.categories.map(category => {
                let smartTag = smartTagResponse.response.smart_tags.filter(smartTag => smartTag.category===category.name)[0]
                //||{category:category.name};
            
                return {...category,
                    smartTag
                }
            }))

        })}
            ).switchMap(categories => appendCategoryQueryObservable(categories, professional)
            )
        .map(categories => {
            return fetchCategoriesFullfilled(categories);
        }).catch((e) => Observable.concat([handleAjaxError(e), fetchCategoriesFullfilled([])]))
    });
}

export function saveSmartTagEpic(action$, store) {
    return action$.ofType(SAVE_SMART_TAG)
    .switchMap(({payload}) => {
        let {smartTag} = payload;
        return Observable.ajax(requestForEndpointAndPayload('put',{
            smart_tags:[smartTag]         
        }))
        .map(({response}) => {
            const smartTag = response.smart_tags[0]
            return saveSmartTagFullfilled(smartTag);
})
    }).catch((e) => Observable.concat([handleAjaxError(e),saveSmartTagFullfilled(null)]));
    
}

function appendCategoryQueryObservable(categories, professional) {
    return Observable.forkJoin(categories.map(category =>
        Observable.ajax(requestForEndpointAndPayload(`category/${category.name}`,{
            professional,
            client_properties:['given_name','family_name','company_name','email'],
            page_size:10,
            index:0
        })).switchMap(result =>{
            return Observable.of({
                ...category,
                client_query:result.response
            })

        })
            ))
}


export function fetchClientsForCategoryEpic(action$) {
    return action$.ofType(FETCH_CLIENTS_FOR_CATEGORY)
    .switchMap(({payload}) => {
    return Observable.ajax(requestForEndpointAndPayload(`category/${payload.category.name}`,{
            professional:payload.professional,
            client_properties:['given_name','family_name','company_name','email'],
            page_size:payload.length,
            page:payload.page
        })).map(({response}) =>{
            return fetchClientsForCategoryFullfilled({...payload.category,client_query:response})

        })
    }
    )
}


