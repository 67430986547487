// @flow

export const FETCH_TAGS = "FETCH_TAGS";
export const FETCH_TAGS_FULLFILLED = "FETCH_TAGS_FULLFILLED";
export const FETCH_SELECTED_TAG = "FETCH_SELECTED_TAG";
export const FETCH_SELECTED_TAG_FULLFILLED = "FETCH_SELECTED_TAG_FULLFILLED";
export const CLEAR_SELECTED_TAG = "FETCH_SELECTED_TAG";
export const SAVE_TAG = "SAVE_TAG";
export const SAVE_TAG_FULLFILLED = "SAVE_TAG_FULLFILLED";
export const DELETE_TAG = "DELETE_TAG";
export const DELETE_TAG_FULLFILLED = "DELETE_TAG_FULLFILLED";
export const SELECT_TAG = "SELECT_TAG";
export const SELECT_TAG_FULLFILLED = "SELECT_TAG_FULLFILLED";
export const SET_SAVE_TAG_ERRORS = "SET_SAVE_TAG_ERRORS";

const selectTag = (tag) => {

    return {
        type : SELECT_TAG,
        payload : {tag}
    }
}

const setSaveTagErrors = (errors) => {
    return {
        type : SET_SAVE_TAG_ERRORS,
        payload : {errors}
    }
}

const fetchTags = (professional, page, length) =>  { 
    return {
        type:FETCH_TAGS,
        payload: {professional, 
                    page,
                    length}
    }
}

const fetchTagsFullfilled = (tags) =>  { 
    return {
        type:FETCH_TAGS_FULLFILLED,
        payload: {tags}
    }
}

const fetchSelectedTag = (selectedTag:{uuid:string}) => {
    return {
        type: FETCH_SELECTED_TAG,
        payload: {selectedTag}
    }
}

const fetchSelectedTagFullfilled = (selectedTag) => {
    return {
        type: FETCH_SELECTED_TAG_FULLFILLED,
        payload: {selectedTag}
    }
}


const saveTag = (tag) =>  {     
            return {
                type:SAVE_TAG,
                payload: {tag}
            }
        }

const saveTagFullfilled = (tag) =>  { 
            return {
                type:SAVE_TAG_FULLFILLED,
                payload: {tag}
            }
        }


const deleteTag = (tag) => { 
            return {
                type:DELETE_TAG,
                payload: {tag}
            }
        }

const deleteTagFullfilled = (tag) => { 
            return {
                type:DELETE_TAG_FULLFILLED,
                payload: tag
            }
        }

export { setSaveTagErrors, selectTag, fetchTags, fetchTagsFullfilled, saveTag, saveTagFullfilled, deleteTag, deleteTagFullfilled, fetchSelectedTag, fetchSelectedTagFullfilled}