import React, { Component } from 'react'
import { Form, Button, Icon, Segment,  Tab, Divider, Header, Dropdown, Message, Checkbox, TextArea} from 'semantic-ui-react'
import moment from 'moment-timezone'
import RRuleGenerator from '../../widgets/react-rrule-generator-semantic';
import './CampaignDetail.css';
import PropTypes from 'prop-types';


import LoadingIndicator from 'widgets/LoadingIndicator';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, 
    ContentState, 
    bold, 
    italic,
    underline, 
    superscript, 
    subscript, 
    fontSize,
    unordered,
    ordered,
    indent,
    outdent,
    left,
    center,
    right,
    color,
    link,
    unlink,
    image,
    eraser,
    undo,
    redo,
    justify,
    strikethrough,
    monospace
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import TagsSelection from '../../widgets/TagsSelection';
import { TextFormField } from 'widgets/FormWidgets';
import { PreviewButton } from '../../widgets/PreviewButton';
import { arrayToSentence } from '../../utils/stringUtils';
import { normalize } from '../../utils/normalizeUtils';


export class CampaignDetail extends Component {
    constructor(props) {
        super(props)
        this.state ={
          selectedCampaign:{
            professional:props.professional,
            office:props.office,
            notes:''
          },
          errors:[]
        };
        this.el = null;
    }

    static getDerivedStateFromProps(props, state) {

      if (state.selectedCampaign.uuid !== props.selectedCampaign.uuid) {
          return {...state,selectedCampaign:props.selectedCampaign}
      }
      return state;
  }
    

    componentDidMount() {
    }

    handleSaveCampaign(savedCampaign) {
            this.props.handleSaveChanges(savedCampaign)
    }

    handleDeleteCampaign(deletedCampaign) {
            this.props.handleDeleteCampaign(deletedCampaign)
    }

    selectedCampaign() {
        if(this.props.selectedCampaign) {
            return this.props.selectedCampaign;
        }
        else {
            return {
              professional:this.props.professional,  
              office:this.props.office,
              categorie_names:[]
            }
        }
    }


  updateCampaign(key, value) {
    if(arguments.length === 1) {
      let selectedCampaign = {...this.state.selectedCampaign,...key}||{...this.props.selectedCampaign,...key};
      selectedCampaign[key] = value;
      this.setState({...this.state,selectedCampaign})
    } else {
        let selectedCampaign = {...this.state.selectedCampaign}||{...this.props.selectedCampaign};
        selectedCampaign[key] = value;
        this.setState({...this.state,selectedCampaign})
    }
}

setCampaignSchedule(schedule) {
    if(schedule==='CUSTOM'&&!this.state.selectedCampaign.recurrence_rules) {
      const today = moment().startOf('day').utc().format('YYYYMMDD[T]HHmmss[Z]');
      const inOneYear = moment().startOf('day').add(1,'year').utc().format('YYYYMMDD[T]HHmmss[Z]');
      this.updateCampaign(
                            {
                              recurrence_rules:`DTSTART:${today}\nRRULE:FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;UNTIL=${inOneYear};WKST=SU`,
                              holiday:schedule
                            }
                          );   
    } else {
      
    this.updateCampaign('holiday',schedule);
    }
}


validateSave() {
  const campaignToBeSaved = this.campaignToBeSaved();
  const errors = [];
  if (!campaignToBeSaved.name) {
    errors.push('a campaign needs a name');
  }
  if ( (!campaignToBeSaved.tags||campaignToBeSaved.tags.length < 1)
    && (!campaignToBeSaved.categorie_names || campaignToBeSaved.categorie_names.length < 1)
    ) {
      errors.push('you need to define an audience');
  }

  if (!campaignToBeSaved.holiday 
    && !campaignToBeSaved.recurrence_rules) {
      errors.push('you need to define a schedule');
  } 
  if (!campaignToBeSaved.email_content
    || !campaignToBeSaved.email_subject
    ) {
      errors.push('you need to create content (subject and body)');
  }
  if(errors.length>0) {
    this.setState({...this.state,errors});
    this.el.scrollIntoView({ behavior: 'smooth' });
    return false;
  }
  this.setState({...this.state,errors});
  return true;
}

getCampaignSchedule() {
  if(this.state.selectedCampaign.holiday) {
    return this.state.selectedCampaign.holiday;
  }
  if(this.state.selectedCampaign.recurrence_rules) {
    return 'CUSTOM';
  }
  return null;
}

  

  campaignToBeSaved() {
    const campaignToBeSaved = {...this.state.selectedCampaign};
    if(campaignToBeSaved.holiday==='CUSTOM') {
      campaignToBeSaved.holiday = null;
    }

    return campaignToBeSaved;
  }

  saveSelectedCampaign() {
    if(this.validateSave()) {
      const camp2BSaved = this.campaignToBeSaved()
      if(!camp2BSaved.communication_message_template) {
        camp2BSaved.communication_message_template = this.props.template.uuid;
      }
      this.props.saveSelectedCampaign(camp2BSaved);
    }
  }



    render () {

      const showCustomerScheduler = this.getCampaignSchedule()==='CUSTOM';
      
        return (
          <Segment><div ref={el => { this.el = el; }} />
            <LoadingIndicator loading={this.props.saving} />
            {this.state.errors.length>0&&<Message negative onDismiss={() => this.setState({...this.state,errors:[]})}>
    <Message.Header>Please add missing data</Message.Header>
    <ul>
      {this.state.errors.map(error => <li key={error}>{error}</li>)}
    </ul>
  </Message>}
            {this.props.selectedCampaign&&<Tab activeIndex={this.state.tagTabIndex} onTabChange={(event, data) => this.setState({...this.state,tagTabIndex:data.activeIndex})} panes={ [
                { menuItem: 'Campaign', render: () => 
                    <Tab.Pane>
 <Form>

    <Form.Input label="Campaign Name*"  value={this.state.selectedCampaign.name||''} placeholder='Enter a name...' onChange={(e, {value}) => this.updateCampaign('name',value)}/>
    <TextFormField 
            valueKey='notes'
            control={TextArea} 
            label='Description' 
            object={this.state.selectedCampaign}
            update={(value, key) => this.updateCampaign(key, value)}         
        />
    <Form.Field>
      <label>This campaign is turned</label>
    <span style={{fontSize:'12px'}}>OFF</span> <Checkbox toggle onChange={(e, {checked}) => {this.updateCampaign({status:checked?'ACTIVATING':'PAUSED'})}} checked={this.state.selectedCampaign.status === 'ACTIVATING' || this.state.selectedCampaign.status === 'RUNNING'} /> <span style={{fontSize:'12px'}}>ON</span>
    </Form.Field>
    <Divider horizontal>
      <Header as='h4'>
        <Icon name='users' />
        Audience*
      </Header>
    </Divider>
    <Form.Group widths='equal'>
    <Form.Field>
  <label>Select SmartTags</label>
    <Dropdown
      clearable='true'
      fluid
      multiple
      search
      value={this.state.selectedCampaign.categorie_names||[]}
      onChange={(e, {value}) => this.updateCampaign('categorie_names',value)}
      selection
      options={this.props.categories.map(category => {
        return {text:category.beautified_name,value:category.name}
      })}
      placeholder='Select SmartTags'
  />
  </Form.Field>
 
  <Form.Field>
  <label>Select Tags</label>
  <TagsSelection search clearable='true' fluid availableTags={this.props.tags} selectedTags={this.state.selectedCampaign.tags} onChange={(tags) => this.updateCampaign('tags',tags)}/>
  </Form.Field>
  </Form.Group>
  <Divider horizontal>
      <Header as='h4'>
        <Icon name='calendar alternate outline' />
        Schedule
      </Header>
    </Divider>
    <Form.Group widths='equal'>
    <Form.Field>
      <label>Schedules*</label>
      <Dropdown clearable='true' placeholder='Schedules' value={this.getCampaignSchedule()} search selection options={[{text:'Custom...',value:'CUSTOM'}].concat(this.props.holidayRecords.map(holiday => 
  {return {text:'Every '+holiday.beautified_name,value:holiday.name}}))} onChange={(event,{value}) =>  this.setCampaignSchedule(value)}/>

</Form.Field>
    <Form.Field><ul>{campaignInfoText(
      this.props.selectedCampaign,
      this.props.tags,
      this.props.categories
      ).map((text, index) => <li key={`li${index}`}>{text}</li>)}</ul></Form.Field>
    </Form.Group>
 {showCustomerScheduler&&<Form.Field>
      <label>Custom Schedule</label>
    <RRuleGenerator
    onChange={(rrule) => this.updateCampaign('recurrence_rules',rrule)}
    value={this.state.selectedCampaign.recurrence_rules}
    config={{
      hideStart: false,
      repeat: ['Yearly', 'Monthly'],
      end :['On date','After'],
      weekStartsOnSunday: true,
      hideError: false,
    }}
  />
  </Form.Field>}
    <SaveDeleteButton 
        isCreate={!this.state.selectedCampaign.uuid} 
        saveAction={() => this.saveSelectedCampaign()} 
        deleteAction={() => {
          if(window.confirm('Do you really want to delete this campaign?')) {
            this.props.deleteSelectedCampaign(this.props.selectedCampaign.uuid)
          }
        }} 
        emailContent={this.state.selectedCampaign.email_content||''} 
        professional={this.props.professional}
        template={this.props.template}
        />
    
    </Form>

    
                    </Tab.Pane> 
                },
                { menuItem: 'Content', render: () => 
                    <Tab.Pane>
                      <Form>
                      <SendCommunication campaign={this.state.selectedCampaign} updateCampaign={(key, value) => this.updateCampaign(key,value)}/>
   <SaveDeleteButton 
      isCreate={!this.props.selectedCampaign.uuid} 
      saveAction={() => this.saveSelectedCampaign()} 
      deleteAction={() => {
        if(window.confirm('Do you really want to delete this campaign?')) {
          this.props.deleteSelectedCampaign(this.props.selectedCampaign.uuid)
        }
      }} 
      emailContent={this.state.selectedCampaign.email_content||''} 
      professional={this.props.professional}
      template={this.props.template}
    />
   </Form> </Tab.Pane> }

            ]} />} 
           
          </Segment>)
    }
}

CampaignDetail.propTypes = {
    tags:PropTypes.array,
    handleSaveChanges:PropTypes.func,
    handleDeleteTag:PropTypes.func
}

class SendCommunication extends Component{
    constructor(props) {
        super(props);
        this.state = {
            campaign:props.campaign,
            bodyContentState:convertToRaw(ContentState.createFromBlockArray(htmlToDraft(props.campaign.email_content || '')))
        };
    }

    updateCampaign(key, value) {
        this.props.updateCampaign(key,value);
    }


    static getDerivedStateFromProps(props, state) {

      if (state.campaign.uuid !== props.campaign.uuid) {
          return {...state, 
            bodyContentState:convertToRaw(ContentState.createFromBlockArray(htmlToDraft(props.campaign.email_content || ''))),
            campaign:props.campaign
          }
      }
      return state;
  }


    render() {
 return <React.Fragment>


<Form.Input 
    label='Subject*' 
    type='text' 
    placeholder="Your email subject here..."
    onChange={(event, {value}) => this.updateCampaign('email_subject', value)}
    value={this.props.campaign.email_subject||''}
/>  
<Form.Field>
  <label>Body*</label>
<div style={{height:'400px'}}>
        <Editor
  wrapperClassName="wrapper-class"
  editorClassName="editor-class"
  toolbarClassName="toolbar-class"
  //initialContentState={}
  contentState={this.state.bodyContentState}
  toolbar={editorToolbar}
  //initialEditorState={editorState}
  /*wrapperStyle={<wrapperStyleObject>}
  editorStyle={<editorStyleObject>}
  toolbarStyle={<toolbarStyleObject>}*/
  onChange={(contentState) => this.updateCampaign('email_content', draftToHtml(contentState))}
/>
</div></Form.Field>
{/*<div style={{marginTop:'10px'}}>
<Button.Group><PreviewButton templatePersonalization={this.state.campaignemail_content} sender={this.props.professional} communicationMessageTemplate={this.props.communicationMessageTemplates[0].uuid} communicationType='EMAIL'/>
</Button.Group></div>*/}
            </React.Fragment>
   
}
}


const editorToolbar = {
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', /*'embedded', 'emoji', */'image', 'remove', 'history'],
    inline: {
      inDropdown: true,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
      bold: { icon: bold, className: undefined },
      italic: { icon: italic, className: undefined },
      underline: { icon: underline, className: undefined },
      strikethrough: { icon: strikethrough, className: undefined },
      monospace: { icon: monospace, className: undefined }, 
      superscript: { icon: superscript, className: undefined },
      subscript: { icon: subscript, className: undefined },
    },
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    fontSize: {
      icon: fontSize,
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    fontFamily: {
      options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    list: {
      inDropdown: true,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['unordered', 'ordered', 'indent', 'outdent'],
      unordered: { icon: unordered, className: undefined },
      ordered: { icon: ordered, className: undefined },
      indent: { icon: indent, className: undefined },
      outdent: { icon: outdent, className: undefined },
    },
    textAlign: {
      inDropdown: true,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['left', 'center', 'right', 'justify'],
      left: { icon: left, className: undefined },
      center: { icon: center, className: undefined },
      right: { icon: right, className: undefined },
      justify: { icon: justify, className: undefined },
    },
    colorPicker: {
      icon: color,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
        'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
        'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
        'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
        'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
        'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
    },
    link: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      dropdownClassName: undefined,
      showOpenOptionOnHover: true,
      defaultTargetOption: '_self',
      options: ['link', 'unlink'],
      link: { icon: link, className: undefined },
      unlink: { icon: unlink, className: undefined },
    },/*
    emoji: {
      icon: emoji,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      emojis: [
        '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
        '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
        '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
        '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
        '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
        '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
        '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
        '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
        '✅', '❎', '💯',
      ],
    },
    embedded: {
      icon: embedded,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      defaultSize: {
        height: 'auto',
        width: 'auto',
      },
    },*/
    image: {
      icon: image,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: true,
      uploadCallback: undefined,
      previewImage: false,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: 'auto',
        width: 'auto',
      },
    },
    remove: { icon: eraser, className: undefined, component: undefined },
    history: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['undo', 'redo'],
      undo: { icon: undo, className: undefined },
      redo: { icon: redo, className: undefined },
    },
  };

 const SaveDeleteButton = ({saveAction, deleteAction, isCreate, emailContent, professional, template}) =>  <Button.Group>
   <Button color='green' onClick={() => saveAction()} >{isCreate?'Create':'Save'}</Button>
   <Button disabled={isCreate} color='red' onClick={() => deleteAction()} >Delete</Button>
   {template&&<PreviewButton buttonSize='medium' templatePersonalization={emailContent} 
   sender={professional} 
   communicationMessageTemplate={template.uuid} communicationType='EMAIL'/>
   }
   </Button.Group>


function campaignInfoText(campaign, tags, categories) {
    let infoTexts = [];
    if(campaign.last_processing_date) {
      const lastProcessingDateString = moment(campaign.last_processing_date).format('ddd MMM Do YYYY')
      infoTexts.push(`This campaign ran last on ${lastProcessingDateString}.`);
    } else {
      infoTexts.push('This campaign was never run before.');
    }
    if(campaign.status === 'RUNNING' && campaign.next_processing_date) {
      const nextProcessingDateString = moment(campaign.next_processing_date).utc().format('ddd MMM Do YYYY')
      infoTexts.push(` The ${campaign.last_processing_date?'next':'first'} run is scheduled for ${nextProcessingDateString}.`);
    } else if (campaign.status === "ACTIVATING"){
      infoTexts.push(' The next run date will be calculated shortly.');
    }
    else {
      infoTexts.push(' There is currently no future run scheduled.');
    }


    /*const tagNames = (campaign.tags||[]).map(cTag => tags.filter(tTag => {
      return tTag.uuid === cTag
    })[0].name.toUpperCase());*/
    const tagNames = normalize(campaign.tags||[], tags).matched.map(cTag => cTag.name.toUpperCase());
    const categoryNames = campaign.categorie_names?campaign.categorie_names.map(cCategory => categories.filter(tCategory => {
      return tCategory.name === cCategory
    })[0].beautified_name.toUpperCase()):[];

    if(tagNames.length > 0 || categoryNames.length > 0) {
      let audienceText = "You have selected clients "
      if(tagNames.length>0) {
        audienceText = audienceText + `tagged  ${arrayToSentence(tagNames,3)}`;
        if(categoryNames.length>0) {
          audienceText = audienceText + " or " 
        }
      }
      if(categoryNames.length > 0) {
        audienceText = audienceText + `that are members of ${arrayToSentence(categoryNames,3)}`;
      }
      infoTexts.push(audienceText+".");
    } else {
      infoTexts.push('You have not selected an audience for this campaign yet.')
    }
    return infoTexts;
}