import * as React from 'react';
import { Message, Button } from 'semantic-ui-react';
import { arrayToSentence } from '../../mic/utils/Format';
import { openGoogleEventInGoogleCalendar, GOOGLE_EVENT, openMicrosoftEventInMicrosoftCalendar } from '../../utils/appointmentUtils';

const  SyncedEventAttendeeDisplay = ({syncedEvent}) => {


    if(!syncedEvent) {
        return null;
    }
 
    const attendees = syncedEvent.attendees&&syncedEvent.attendees.length>0?`Attendees: ${arrayToSentence(syncedEvent.attendees, 3, (attendee) => {
        if(attendee.displayName) {
            return attendee.displayName;
        } 
        return attendee.email;
    })}`:'no attendees';
    const typeName = syncedEvent.type===GOOGLE_EVENT?'Google':'Microsoft/Outlook';
    const icon = syncedEvent.type===GOOGLE_EVENT?'google':'windows';
    const openEventEditor = syncedEvent.type===GOOGLE_EVENT?openGoogleEventInGoogleCalendar:openMicrosoftEventInMicrosoftCalendar;

    return  (<Message>
        <Message.Header>{`${typeName} Event Info`}</Message.Header>
        {`This appointment is linked to a ${typeName} Calendar Event.`}<br />
{attendees}
    <br />
                        <Button size='mini' color='blue' content={`Edit in ${typeName} Calendar`} onClick={() => openEventEditor(syncedEvent)} icon={icon} />
    </Message>)
}

export default SyncedEventAttendeeDisplay;