import { connect } from 'react-redux';
import {Settings} from 'components/Settings/Settings';
import { fetchSettings, saveSettings, uploadClients, reloadSyncSettings } from 'actions/SettingsActions';
import { unsavedChanges, handleError } from 'actions/SessionActions';
import { toggleVisibilityOfMicrosoftCalendar, toggleVisibilityOfGoogleCalendar } from '../../actions/SettingsActions';

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onFetchSettings: () => dispatch(fetchSettings()),
        onSaveSettings:(settings) => dispatch(saveSettings(settings)),
        onImport:(professional,csvDataURL) => dispatch(uploadClients(professional,csvDataURL)),
        flagAsUnsavedChanges:(hasUnsavedChanges) => dispatch(unsavedChanges(hasUnsavedChanges)),
        refreshSyncSettings:(event) => dispatch(reloadSyncSettings()),
        handleError:(error) => dispatch(handleError(error)),
        onToggleVisibilityOfMicrosoftCalendar: (calendar) => {
            dispatch(toggleVisibilityOfMicrosoftCalendar(calendar))
        },
        onToggleVisibilityOfGoogleCalendar: (calendar) => {
            dispatch(toggleVisibilityOfGoogleCalendar(calendar))
        }
    }

}
function mapStateToProps(state) {
    const professional = state.settings.professionals[0];
    const office = state.settings.offices.filter(currentOffice => professional.office === currentOffice.uuid)[0]
    return {
        googleSyncSettings: state.settings.google_sync_settings,
        googleCalendarSyncs: state.settings.google_calendar_syncs,
        microsoftSyncSettings: state.settings.microsoft_sync_settings,
        microsoftCalendarSyncs: state.settings.microsoft_calendar_syncs,
        zoomSettings: state.settings.zoom_settings,
        communicationTypes : state.settings.communication_types,
        reminderOffsets : state.settings.reminder_offsets,
        scope : state.session.scope,
        at : state.session.at,
        communicationMessageTemplates : state.settings.communication_message_templates,
        loading : state.settings.loading,
        uploadingImport : state.settings.uploadingImport,
        occasions : state.settings.occasions,
        dataImports : state.settings.data_imports||[],
        settings : settings(state.settings.companies[0],
            office,
            professional,
            state.settings.users[0]
        ),
        bookingAPIKey:state.settings.office_booking_api_key,
        features:state.session.features
    };
}

const SettingsContainer = connect(mapStateToProps, mapDispatchToProps)(Settings);

export {SettingsContainer}

function settings(company, office, professional, user) {
    if(company&&office&&professional&&user) {
        return {company, office, professional, user}
    }
    return null;
}