export const SHOW_MIC = 'SHOW_MIC'
export const SHOW_MIC_FULLFILLED = 'SHOW_MIC_FULLFILLED'
export const RESET_MIC_TOKEN = 'RESET_MIC_TOKEN'


const showMIC = (micIsOpen) => {
    return {
        type : SHOW_MIC,
        payload : micIsOpen
    }
}


const showMICFullfilled = (payload) => {
    return {
        type: SHOW_MIC_FULLFILLED,
        payload
    }
}

const resetMICToken = () => {
    return {
        type : RESET_MIC_TOKEN
    }
}

export {showMIC, showMICFullfilled, resetMICToken}