import { connect } from 'react-redux';
import {AppointmentDetail} from 'components/AppointmentDetail/AppointmentDetail'

const mapStateToProps = (state, ownProps) => {
    return {
        prop: state.prop
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSaveChanges: (appt) => {
            dispatch({})
        }
    }
}

const AppointmentDetailContainer = connect(mapStateToProps, mapDispatchToProps)(AppointmentDetail);


export {AppointmentDetailContainer}
