export const FETCH_SETTINGS = "FETCH_SETTINGS";
export const FETCH_SETTINGS_FULLFILLED = "FETCH_SETTINGS_FULLFILLED";
export const SAVE_SETTINGS = "SAVE_SETTINGS";
export const SAVE_SETTINGS_FULLFILLED = "SAVE_SETTINGS_FULLFILLED";
export const IMPORT_CLIENTS = "IMPORT_CLIENTS";
export const IMPORT_CLIENTS_FULLFILLED = "IMPORT_CLIENTS_FULLFILLED";
export const RELOAD_SYNC_SETTINGS = "RELOAD_SYNC_SETTINGS";
export const RELOAD_SYNC_SETTINGS_FULLFILLED = "RELOAD_SYNC_SETTINGS_FULLFILLED";
export const TOGGLE_VISIBILITY_OF_MICROSOFT_CALENDAR = "TOGGLE_VISIBILITY_OF_GOOGLE_CALENDAR";
export const TOGGLE_VISIBILITY_OF_MICROSOFT_CALENDAR_FULLFILLED = "TOGGLE_VISIBILITY_OF_GOOGLE_CALENDAR_FULLFILLED";
export const TOGGLE_VISIBILITY_OF_GOOGLE_CALENDAR = "TOGGLE_VISIBILITY_OF_MICROSOFT_CALENDAR";
export const TOGGLE_VISIBILITY_OF_GOOGLE_CALENDAR_FULLFILLED = "TOGGLE_VISIBILITY_OF_MICROSOFT_CALENDAR_FULLFILLED";

export function reloadSyncSettings() {
    return {
        type: RELOAD_SYNC_SETTINGS
    }
}

export function reloadSyncSettingsFullfilled({google_sync_settings, google_calendar_syncs, microsoft_sync_settings, microsoft_calendar_syncs, zoom_settings}) {
    return {
        type: RELOAD_SYNC_SETTINGS_FULLFILLED,
        payload:{
                google_sync_settings,
                google_calendar_syncs,
                microsoft_sync_settings, 
                microsoft_calendar_syncs,
                zoom_settings
            }
    }
}

export function fetchSettings() {
    return {
        type: FETCH_SETTINGS
    }
}

export function fetchSettingsFullfilled(payload) {
    return {
        type: FETCH_SETTINGS_FULLFILLED,
        payload
    }
}

export function saveSettings(settings) {
    return {
        type: SAVE_SETTINGS,
        payload : settings
    }
}

export function saveSettingsFullfilled(result) {
    return {
        type: SAVE_SETTINGS_FULLFILLED,
        payload : result
    }
}

export function uploadClients(professional, csvDataURL) {
    return {
        type: IMPORT_CLIENTS,
        payload: {professional:professional.uuid,
            csvDataURL}
    }
}

export function uploadClientsFullfilled(imports) {
    return {
        type: IMPORT_CLIENTS_FULLFILLED,
        payload:imports
    }
}

export function toggleVisibilityOfMicrosoftCalendar(calendar) {
    return {
        type: TOGGLE_VISIBILITY_OF_MICROSOFT_CALENDAR,
        payload:{
            calendar:{uuid:calendar.uuid,
            status:calendar.status.includes('IS_NOT_VISIBLE')?'DOES_SYNC_IS_VISIBLE':'DOES_NOT_SYNC_IS_NOT_VISIBLE'}
        }
    }
}

export function toggleVisibilityOfMicrosoftCalendarFullfilled(calendar) {
    return {
        type: TOGGLE_VISIBILITY_OF_MICROSOFT_CALENDAR_FULLFILLED,
        payload:{
            calendar
        }
    }
}

export function toggleVisibilityOfGoogleCalendar(calendar) {
    return {
        type: TOGGLE_VISIBILITY_OF_GOOGLE_CALENDAR,
        payload:{
            calendar:{uuid:calendar.uuid,
            status:calendar.status.includes('IS_NOT_VISIBLE')?'DOES_SYNC_IS_VISIBLE':'DOES_NOT_SYNC_IS_NOT_VISIBLE'}
        }
    }
}

export function toggleVisibilityOfGoogleCalendarFullfilled(calendar) {
    return {
        type: TOGGLE_VISIBILITY_OF_GOOGLE_CALENDAR_FULLFILLED,
        payload:{
            calendar
        }
    }
}