// @flow
import React, {Component} from 'react'
import DnDCalendar from  'widgets/DnDCalendar'
import moment from 'moment';
import BigCalendar from  'react-big-calendar'
import { AppointmentDetail } from 'components/AppointmentDetail/AppointmentDetail'
import {eventFromAppointment, REQUEST} from 'utils/appointmentUtils'
import PropTypes from 'prop-types';
import Wrapper from 'widgets/Wrapper'
import ComponentWrapper from 'widgets/ComponentWrapper/ComponentWrapper'
import { AppointmentRequestDetail } from 'components/AppointmentRequestDetail/AppointmentRequestDetail';
import { urlParams } from 'utils/locationUtils';
import './Calendar.css'
import { GOOGLE_EVENT } from 'utils/appointmentUtils';
import { openGoogleEventInGoogleCalendar } from 'utils/appointmentUtils';
import { openMicrosoftEventInMicrosoftCalendar, MICROSOFT_EVENT, HOLIDAY_EVENT, BIRTHDAY } from '../../utils/appointmentUtils';
/*
Documentation for BigCalendar:
http://intljusticemission.github.io/react-big-calendar/examples/index.html
*/

let allViews = ['month','week','work_week','day'];//Object.keys(BigCalendar.Views).map(k => BigCalendar.Views[k])
BigCalendar.momentLocalizer(moment);

let formats = {
  dateFormat: 'DD',
  dayFormat: (date, culture, localizer) => localizer.format(date, 'ddd MM/DD', culture),
  monthHeaderFormat: (date, culture, localizer) => localizer.format(date, 'MMMM YYYY', culture)
}

const DEFAULT_INITIAL_VIEW = 'week';

export class Calendar extends Component {
  constructor(props:{events:Array,handleDeleteAppointment:Function,onSaveAppointment:Function}){
    super(props);
    this.state = {
      events:props.events,
      selectedTags: []
    }
    this.bound_initizeData = this.initializaData.bind(this);
    this.updateInterval = null;
  }

  initializaData() {

    this.props.onClearSelectedAppointmentRequest();
    this.props.onClearSelectedAppointment();
    this.props.onFetchTags( this.props.professional, 0, 1000);
 
    this.props.onFetchDates(this.props.date, this.props.view, this.props.professional, this.state.selectedTags)
    this.props.onFetchDateRequests(this.props.date, this.props.view, this.props.professional)
    this.props.onFetchGoogleEvents(this.props.date, this.props.view, this.props.professional)
    this.props.onFetchMicrosoftEvents(this.props.date, this.props.view, this.props.professional)
    this.props.onFetchBirthdays(this.props.date, this.props.view, this.props.professional)
    this.props.onFetchHolidayEvents(this.props.date, this.props.view)
  }



  onTagChange = async (value, key) => {
    await this.setState({
      ...this.state,
      selectedTags: value
    })
    let date = this.props.date ? this.props.date : new Date();
    this.props.onFetchDates(date, this.props.view, this.props.professional, this.state.selectedTags)
  }

  componentDidMount() {
    document.addEventListener('professionalChanged',  this.bound_initizeData, false);
    this.props.onFetchTags( this.props.professional, 0, 1000);
    let date = this.props.date?this.props.date:new Date();
    const {d, ar} = urlParams();
    if(d) {
      date = moment(d,"MM-DD-YYYY").toDate();
    }
    this.props.onFetchDates(date, DEFAULT_INITIAL_VIEW, this.props.professional, this.state.selectedTags)
    this.props.onFetchDateRequests(date, DEFAULT_INITIAL_VIEW, this.props.professional, ar)
    this.props.onFetchGoogleEvents(date, DEFAULT_INITIAL_VIEW, this.props.professional)
    this.props.onFetchMicrosoftEvents(date, DEFAULT_INITIAL_VIEW, this.props.professional)
    this.props.onFetchBirthdays(date, DEFAULT_INITIAL_VIEW, this.props.professional)
    this.props.onFetchHolidayEvents(date,DEFAULT_INITIAL_VIEW)
    this.updateInterval = window.setInterval(() => {
      if(!this.props.loading 
        && !this.props.selectedAppointment
        && !this.props.selectedAppointmentRequest) {
        this.props.onFetchDates(this.props.date, this.props.view, this.props.professional, this.state.selectedTags)
        this.props.onFetchDateRequests(this.props.date, this.props.view, this.props.professional)
        this.props.onFetchGoogleEvents(this.props.date, this.props.view, this.props.professional)
        this.props.onFetchMicrosoftEvents(this.props.date, this.props.view, this.props.professional)
      }
    }, 1*60*1000);
  }

  componentWillUnmount() {
    document.removeEventListener('professionalChanged', this.bound_initizeData);
    window.clearInterval(this.updateInterval)
  }

  createNewAppointment(slotInfo, view) {
    let start = moment(slotInfo.start);
    let end = moment(slotInfo.end);
    if(view==='month' && start.isSame(end)) {
      start.hours(12);
      end.hours(12);
      end.minutes(30);
    }
    this.props.onSelectAppointment({
      start_timestamp:start.toISOString(),
      end_timestamp:end.toISOString(),
      professionals:[this.props.professional],
      appt_reminders:[],
      clients:[],
      confirmed_clients:[],
      notify_clients : this.props.notifyClients,
      owning_professional : this.props.professional,
      tags:[],
      google_events:[],
      microsoft_events:[],
      zoom_meeting_events:[]
    })
  }

  editAppointment(eventInfo) {
    this.props.onSelectAppointment(eventInfo.appointment)
  }

  editRequest(eventInfo) {
    this.props.onSelectApptRequest(eventInfo.appointmentRequest)
  }

  updateAppointment(value, valueKey) {
    let updatedAppointment = {...this.state.selectedAppointment};
    updatedAppointment[valueKey] = value;
    this.setState({
      ...this.state,
      selectedAppointment:updatedAppointment
    })
  }

  deleteAppointment(appointment) {
    let events = this.state.events.filter((event) =>  event.appointment.id !== appointment.id)
    this.setState({
      ...this.state,
      events,
      selectedAppointment:null
    })
    if(this.props.handleDeleteAppointment) {
      this.props.handleDeleteAppointment(appointment)
    }
  }

  saveSelectedAppointment(appointment) {
    if(this.props.onSaveAppointment) {
      this.props.onSaveAppointment(appointment)
    }
  }

  saveSelectedAppointmentRequest(apptRequest) {
    if(this.props.onSaveAppointmentRequest) {
      this.props.onSaveAppointmentRequest(apptRequest)
    }
  }

  moveEvent({ event, start, end }) {
    const { events } = this.state
    if(event.type===REQUEST) {
        if (window.confirm('Appointment Requests can not be moved! Do you want to edit instead?')) {
            this.editRequest(event)
        }
        return;
    }
    if(event.type===GOOGLE_EVENT) {
      if (window.confirm('Google Events can not be moved! Do you want to edit instead?')) {
        openGoogleEventInGoogleCalendar(event.calendarEvent);
      }
      return;
  }

if(event.type===MICROSOFT_EVENT) {
  if (window.confirm('Microsoft or Outlook Events can not be moved! Do you want to edit instead?')) {
    openMicrosoftEventInMicrosoftCalendar(event.calendarEvent);
  }
  return;
}
  
    const idx = events.indexOf(event)
    const appointment = {
      ...event.appointment,
      start_timestamp:moment(start).toISOString(),
      end_timestamp:moment(end).toISOString()
    }
    const updatedEvent = eventFromAppointment(appointment)

    const nextEvents = [...events]
    nextEvents.splice(idx, 1, updatedEvent)

    this.setState({
      events: nextEvents,
    })
    if(this.props.onSaveAppointment) {
      this.props.onSaveAppointment(appointment)
    }
  }

  showGoogleEventInfo(eventInfo) {
    if(window.confirm('This is a Google Calendar Event.\n  Do you want to open it in Google Calendar now?')){
        openGoogleEventInGoogleCalendar(eventInfo.calendarEvent);
    }
  }
  showMicrosoftEventInfo(eventInfo) {
    if(window.confirm('This is a Microsoft Calendar or Outlook Event.\n  Do you want to open it in Microsoft Calendar or Outlook now?')){
        openMicrosoftEventInMicrosoftCalendar(eventInfo.calendarEvent);
    }
  }
  showHolidayEventInfo(eventInfo) {

  }

  showBirthdayEventInfo(eventInfo) {

  }

  selectEvent(eventInfo) {
      switch(eventInfo.type) {
          case REQUEST:
            this.editRequest(eventInfo);
            break;
          case GOOGLE_EVENT:
            this.showGoogleEventInfo(eventInfo);
            break;
          case MICROSOFT_EVENT:
              this.showMicrosoftEventInfo(eventInfo);
              break;
          case HOLIDAY_EVENT:
                this.showHolidayEventInfo(eventInfo);
                break;
          case BIRTHDAY:
                this.showBirthdayEventInfo(eventInfo);
                break;
          default:
          this.editAppointment(eventInfo)
      }
  }
  resizeEvent = (resizeType, { event, start, end }) => {
    const { events } = this.state

    const nextEvents = events.map(existingEvent => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent
    })

    this.setState({
      events: nextEvents,
    })
  }


  render() {
    return (
      <ComponentWrapper>
        <Wrapper icon="calendar" loading={this.props.loading} style={{padding: 0, border: 0}}>
          <DnDCalendar
            selectedTags={this.state.selectedTags}
            onTagChange={(tags) => this.onTagChange(tags, 'tags')}
            formats={formats}
            length={30}
            onMove={(eventInfo) => this.moveEvent(eventInfo)}
            events={this.props.events}
            views={allViews}
            view={this.props.view}
            onSelectSlot={(slotInfo) => {this.createNewAppointment(slotInfo, this.props.view)}}
            onSelectEvent={(eventInfo) => this.selectEvent(eventInfo)}
            onNavigate={(date, view) => {
              this.props.onFetchDates(date, view, this.props.professional, this.state.selectedTags);
              this.props.onFetchDateRequests(date, view, this.props.professional);
              this.props.onFetchGoogleEvents(date, view, this.props.professional);
              this.props.onFetchMicrosoftEvents(date, view, this.props.professional);
              this.props.onFetchBirthdays(date, view, this.props.professional);
              this.props.onFetchHolidayEvents(date, view);
            }}
            onView={(view) => {
              this.props.onFetchDates(this.props.date, view, this.props.professional, this.state.selectedTags);
              this.props.onFetchDateRequests(this.props.date, view, this.props.professional);
              this.props.onFetchGoogleEvents(this.props.date, view, this.props.professional);
              this.props.onFetchMicrosoftEvents(this.props.date, view, this.props.professional);
              this.props.onFetchBirthdays(this.props.date, view, this.props.professional)
              this.props.onFetchHolidayEvents(this.props.date, view);
            }}
            {...this.props}
          />
        </Wrapper>
        {
          this.props.selectedAppointmentRequest && (
            <AppointmentRequestDetail 
              occasions={this.props.occasions}
              communicationMessageTemplates={this.props.communicationMessageTemplates}
              reminderOffsets={this.props.reminderOffsets}
              communicationTypes={this.props.communicationTypes}
              availableClients={this.props.availableClients}
              handleRejectAppointmentRequest={(apptRequest) => this.props.handleRejectAppointmentRequest(apptRequest)}
              loading={this.props.loading}
              selectedAppointmentRequest={this.props.selectedAppointmentRequest} 
              close={() => {this.props.onClearSelectedAppointmentRequest()}} 
              update={(value, valueKey) => this.updateAppointment(value, valueKey)}
              onSaveAppointmentRequest={(dateRequest) => this.saveSelectedAppointmentRequest(dateRequest)}
              availableTags={this.props.availableTags}
              professional={this.props.professional}
              communicationPreferences={this.props.communicationPreferences}
              appendAppointmentToApptRequest={this.props.appendAppointmentToApptRequest}
              dateLocations={this.props.dateLocations}
              features={this.props.features}
              scope={this.props.scope}
            />
          )
        }
        {
          this.props.selectedAppointment && (
            <AppointmentDetail 
              occasions={this.props.occasions}
              communicationMessageTemplates={this.props.communicationMessageTemplates}
              reminderOffsets={this.props.reminderOffsets}
              communicationTypes={this.props.communicationTypes}
              availableClients={this.props.availableClients}
              deleteAppointment={(appointment) => this.deleteAppointment(appointment)}
              loading={this.props.loading}
              selectedAppointment={this.props.selectedAppointment} 
              close={() => this.props.onClearSelectedAppointment()} 
              update={(value, valueKey) => this.updateAppointment(value, valueKey)}
              saveSelectedAppointment={(appointment) => this.saveSelectedAppointment(appointment)}
              availableTags={this.props.availableTags}
              professional={this.props.professional}
              communicationPreferences={this.props.communicationPreferences}
              dateLocations={this.props.dateLocations}
              zoomSetting={this.props.zoomSetting}
              microsoftSyncSetting={this.props.microsoftSyncSetting}
              googleSyncSetting={this.props.googleSyncSetting}
              features={this.props.features}
              scope={this.props.scope}
            />
          )
        }
      </ComponentWrapper>
    )
  }
}

Calendar.propTypes = {
  events : PropTypes.array,
  onSaveAppointment:PropTypes.func,
  onDeleteAppointment:PropTypes.func,
}

