import React from 'react'
import {
    Icon
} from 'semantic-ui-react';

import './MICIntegration.css';

const MICIntegration = ({ closeMIC, baseURL, token, cssURL }) => (
    <div id='mic_wrapper_open'>
        <div id='mic_wrapper_header'>
            <span onClick={() => closeMIC()} id="mic_close_icon_button">
                <Icon name="window minimize" />
            </span>
        </div>
        <iframe
            title="Message Center"
            name="mic_integration"
            id="mic_integration"
            scrolling="no"
            src={`${baseURL ? baseURL : 'https://messagecenter.mojo.accountants/cgi-bin/WebObjects/bullhornBackend.woa/wa/ClientAPI/'}webClient?t=${token}${cssURL ? `&css=${encodeURIComponent(cssURL)}` : ''}`}
        />
    </div>
)
export default MICIntegration;