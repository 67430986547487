// @flow
import React from 'react'
import { composePreviewURL } from 'utils/previewUtils';
import { Button } from 'semantic-ui-react';


export const PreviewButton = (props:{receiver?:string,sender:string,date?:string,communicationMessageTemplate:string, communicationType:string,templatePersonalization?:string}) => {
    let previewURL = props.previewURL;
    if(!previewURL) {
        previewURL = composePreviewURL(props.receiver, props.sender, props.date, props.communicationMessageTemplate, props.communicationType, props.templatePersonalization) 
    }
    return (<Button size={props.buttonSize || "mini"} onClick={() => window.open(previewURL)} icon="eye" circular/>);   
}